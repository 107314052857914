import React from 'react';

import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';

import { useIsAllowed } from 'hooks/useIsAllowed';

type Props = { children: React.ReactElement };

export const AuthorizedGuard: React.FC<Props> = ({ children }) => {
  const isAllowed = useIsAllowed('Chantier', 'useAdvancedActions');

  return isAllowed ? (
    children
  ) : (
    <div className="px-lg py-sm rounded bg-error-50 text-center flex items-center gap-xs">
      <CrossCircle className="w-md h-md" />
      Accès interdit
      <CrossCircle className="w-md h-md" />
    </div>
  );
};
