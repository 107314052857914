import React, { RefObject } from 'react';

import classNames from 'classnames';

export type Props = {
  contentRef: RefObject<HTMLDivElement | null>;
  isScrollable: boolean;
};

export const ModalBody: React.FC<React.PropsWithChildren<Props>> = ({
  contentRef,
  isScrollable,
  children,
}) => (
  <div
    id="modal-body"
    className={classNames(
      'max-h-full',
      { 'overflow-auto': isScrollable },
      'ml-md tablet:ml-lg mr-xs tablet:mr-sm pr-xs tablet:pr-sm',
    )}
    ref={contentRef}
  >
    {children}
  </div>
);
