import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { LEADS_KEY } from 'features/DashboardCDP/api/useLeads';
import { LEAD_CLIENTS_KEY } from 'features/Leads/api/useLeadClients';
import { Lead } from 'types';

const handleAutoAssignCDPL = ({ lead }: { lead: Lead }): Promise<Lead> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/leads/${lead.id}/addressable-mandataire`;
  return request(requestURL, {
    method: 'PATCH',
  });
};

export const useAutoAssignCDPL = (): {
  isLoading: boolean;
  leadAutoAssignCDPL: typeof handleAutoAssignCDPL;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: leadAutoAssignCDPL, isPending } = useMutation({
    mutationFn: handleAutoAssignCDPL,
    onSuccess: updatedLead => {
      queryClient.invalidateQueries({ queryKey: [LEADS_KEY] });
      queryClient.invalidateQueries({ queryKey: [LEAD_CLIENTS_KEY] });
      toast.success(`Le lead a bien été assigné à un CDPL : ${updatedLead.dealOwner}`);
    },
    onError: () => {
      toast.error("Aucun CDPL n'est disponible");
    },
  });
  return {
    isLoading: isPending,
    leadAutoAssignCDPL,
  };
};
