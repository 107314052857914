import React, { useRef, useState } from 'react';

import {
  FloatingArrow,
  Placement,
  arrow,
  autoUpdate,
  offset,
  safePolygon,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import classNames from 'classnames';

type Props = {
  showDelay?: number;
  trigger: React.ReactElement;
  children?: React.ReactElement;
  position?: Placement;
  width?: string;
  disabled?: boolean;
  height?: string;
  fixedFloating?: boolean;
  offsetY?: number;
  offsetX?: number;
  showOnClick?: boolean;
  bgColor?: string;
  fillColor?: string;
  className?: string;
};

const ARROW_HEIGHT = 8;
const GAP = 3;
export const WithTooltip: React.FC<Props> = ({
  showDelay = 0,
  trigger,
  children,
  position = 'top',
  width = '17.188rem',
  height = 'auto',
  disabled = false,
  fixedFloating,
  offsetX,
  offsetY,
  bgColor = 'bg-neutral-800',
  fillColor,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: position,
    strategy: fixedFloating ? 'fixed' : 'absolute',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: offsetY ?? ARROW_HEIGHT + GAP,
        alignmentAxis: offsetX ?? -16,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    delay: {
      open: showDelay,
      close: 0,
    },

    handleClose: safePolygon(),
    move: false,
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const click = useClick(context);
  const role = useRole(context, {
    role: 'tooltip',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    click,
    role,
  ]);

  return children ? (
    <>
      {React.cloneElement(trigger, {
        ref: refs.setReference,
        ...getReferenceProps(),
        disabled,
      } as any)}
      {isOpen && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          className={classNames(
            'absolute z-50 p-sm text-neutral-0 rounded-xxs flex items-center justify-center text-left whitespace-pre-wrap break-words',
            bgColor,
            className,
          )}
          style={{
            ...floatingStyles,
            width: `${width}`,
            height: `${height}`,
          }}
        >
          {children}
          <FloatingArrow className={fillColor} ref={arrowRef} context={context} />
        </div>
      )}
    </>
  ) : (
    trigger
  );
};
