import React from 'react';

import classNames from 'classnames';

import AnnouncementDuoTone from '../../assets/AnnouncementDuoTone.svg?react';
import CheckCircleOutline from '../../assets/CheckCircleOutline.svg?react';
import InfoCircleOutline from '../../assets/InfoCircleOutline.svg?react';
import WarningOutline from '../../assets/WarningOutline.svg?react';
import { ColoredCircle } from '../ColoredCircle';
import { SimpleModal } from '../Modal';
import { Props as ModalProps } from '../Modal/types';

type Variant = 'warning' | 'error' | 'info' | 'success' | 'branding';

type Props = {
  isOpen: boolean;
  variant?: Variant;
  Icon?: React.ComponentType<{ className: string }>;
  title: string;
  centered?: boolean;
  message: string | React.ReactElement;
};

const iconMatcher = (variant: Variant): React.ReactElement => {
  switch (variant) {
    case 'info':
      return <InfoCircleOutline />;
    case 'warning':
      return <InfoCircleOutline />;
    case 'error':
      return (
        <span className="w-full h-full mb-xxs">
          <WarningOutline />
        </span>
      );
    default:
      return <CheckCircleOutline />;
  }
};

const IconComponent = ({ variant }: { variant: Variant }): React.ReactElement => {
  if (variant !== 'branding') {
    return (
      <ColoredCircle variant={variant} className="mb-md" size="xxl">
        {iconMatcher(variant)}
      </ColoredCircle>
    );
  }

  return (
    <div className="mb-md h-[7.5rem] w-[7.5rem]">
      <AnnouncementDuoTone />
    </div>
  );
};

export const FeedbackMessages: React.FC<Props & Omit<ModalProps, 'children'>> = ({
  variant,
  Icon,
  title,
  centered = false,
  message,
  handleClose,
  ...rest
}) => (
  <SimpleModal
    titleClassName="pb-sm"
    title={
      <div
        className={classNames('w-full flex flex-col', {
          'items-center text-center': centered,
          'ml-xl': handleClose && centered,
        })}
      >
        {variant && <IconComponent variant={variant} />}
        {Icon && <Icon className="mb-md w-[7.5rem] h-[7.5rem]" />}
        <div
          className={classNames({
            '!-mx-xl': centered,
          })}
        >
          {title}
        </div>
      </div>
    }
    centered={centered}
    handleClose={handleClose}
    {...rest}
  >
    <div className={classNames('flex', { 'justify-center': centered })}>{message}</div>
  </SimpleModal>
);
