import React, { useMemo, useState } from 'react';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { Toggle } from '@travauxlib/shared/src/components/DesignSystem/components/Toggle';
import { PersonRole } from '@travauxlib/shared/src/types';

import { usePersons } from 'features/Trombinoscope/api/usePersons';
import { useTeams } from 'features/Trombinoscope/api/useTeams';

import { Header } from './Header';
import { PersonCard } from './PersonCard';

import { useObiwan } from '../hooks/useObiwan';

export const PersonListing: React.FC = () => {
  useObiwan();
  const [showCDPLs, setShowCDPLs] = useState(false);
  const { persons } = usePersons();
  const { teams } = useTeams();

  const filteredPersons = useMemo(() => {
    const activePersons = persons.filter(p => !p.archived);

    if (showCDPLs) {
      return activePersons;
    }

    return activePersons.filter(p => p.role !== PersonRole.CDPL);
  }, [persons, teams, showCDPLs]);

  if (persons.length === 0 || teams.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <div className="container w-full lg-desktop:w-9/12">
        <div className="ml-sm my-md">
          <Toggle checked={showCDPLs} onChange={setShowCDPLs} label="Afficher les CDPL" />
        </div>
        <div className="grid grid-cols-10 gap-lg place-items-center">
          {filteredPersons.map(person => (
            <div
              className="col-span-full sm-desktop:col-span-3 md-desktop:col-span-2"
              key={person.uuid}
            >
              <PersonCard to={`/trombinoscope/${person.uuid}`} person={person} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
