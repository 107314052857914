import React, { useEffect, useState } from 'react';

import {
  FloatingFocusManager,
  Placement,
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import classNames from 'classnames';

import { ActionListItem } from './type';
import { itemMatcher } from './utils/itemMatcher';

type Props = {
  items: ActionListItem[];
  trigger: (toggle: () => void, referenceProps: Object, isOpen: boolean) => React.ReactElement;
  position?: Placement;
  fixedFloating?: boolean;
  offsetX?: number;
  offsetY?: number;
  height?: number;
  className?: string;
  scrollable?: boolean;
  small?: boolean;
  rounded?: boolean;
};

export const ActionList: React.FC<Props> = ({
  items,
  trigger,
  position = 'bottom-end',
  fixedFloating,
  height = 200,
  scrollable,
  small,
  rounded,
  className,
  offsetX,
  offsetY,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handler = (): void => setIsOpen(false);

    window.addEventListener('scroll', handler);

    return () => window.removeEventListener('scroll', handler);
  }, []);

  const { refs, floatingStyles, context } = useFloating({
    placement: position,
    strategy: fixedFloating ? 'fixed' : 'absolute',
    open: isOpen,
    middleware: [
      offset({
        mainAxis: offsetX || 0,
        crossAxis: offsetY || 0,
      }),
    ],
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  });

  const close = (): void => setIsOpen(false);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'click',
  });
  const role = useRole(context);
  const toggleOpen = (): void => {
    setIsOpen(isOpen => !isOpen);
  };
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, role]);

  const referenceProps = {
    ref: refs.setReference,
    ...getReferenceProps(),
  };

  const content = items.flatMap(item => (item.hide ? [] : [itemMatcher(item, close, small)]));

  if (!content.length) {
    return null;
  }

  return (
    <>
      {trigger(toggleOpen, referenceProps, isOpen)}
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={{
              ...floatingStyles,
              width: 'initial',
              borderRadius: rounded ? '8px' : '4px',
              padding: '4px 0',
            }}
            className={classNames('absolute z-50 bg-white border shadow-xs rounded-xxs', className)}
          >
            <div
              style={scrollable ? { maxHeight: `${height}px` } : {}}
              className={classNames('ignore-click-outside', {
                [`overflow-y-scroll`]: scrollable,
              })}
            >
              {content}
            </div>
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
