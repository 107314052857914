import { ConsultationInfoView } from '@travauxlib/shared/src/types/api/admin/ConsultationInfoView';
import { ConsultationOfferView } from '@travauxlib/shared/src/types/api/pro/ConsultationOfferView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { consultationInfo: ConsultationInfoView };

export class ProAcceptConsultationOffer extends BaseStep<Input, { dealUuid: string }> {
  public name = 'Accept consultation offer';

  public fields = [];

  async execute({
    apiURL,
    consultationInfo,
    request,
  }: ExecuteParams<Input>): Promise<{ dealUuid: any }> {
    const allConsultationOffers = await request<{ data: ConsultationOfferView[] }>(
      `${apiURL}/pro/consultation-offers`,
    );

    super.log('Found all consultation info : ');
    allConsultationOffers.data.map(consultationOffer => super.log(consultationOffer.title));

    super.log(`Params consultation info : ${consultationInfo.title}`);

    const targetConsultationInfo = allConsultationOffers.data.find(
      consultationOffer => consultationOffer.title === consultationInfo.title,
    );

    if (!targetConsultationInfo) {
      super.error('Unable to find consultation info');
      return Promise.reject();
    }

    const acceptedConsultationOffer = await request<ConsultationOfferView>(
      `${apiURL}/pro/consultation-offers/${targetConsultationInfo.token}/accept`,
      { method: 'POST', body: {} },
    );

    return { dealUuid: acceptedConsultationOffer.dealUuid };
  }
}
