import { Placement } from '@floating-ui/react';

import { Pagination } from '@travauxlib/shared/src/components/DesignSystem/components/Pagination';
import { usePagination } from '@travauxlib/shared/src/hooks/usePagination';

import { BaseTable } from './BaseTable';
import { useSorting } from './hooks/useSorting';
import { ColumnConfig, Item, RowAction, SizePaddingClassName } from './types';
export type { ColumnConfig } from './types';

type Props<T> = {
  className?: string;
  title?: string;
  size?: SizePaddingClassName;
  columnConfigs: ColumnConfig<T>[];
  items: Item<T>[];
  onRowClick?: (item: T) => void;
  rowActions?: (item: T) => RowAction[];
  // TODO prevent the use of fixFirstColumn if an item has additionalRowContent prop
  fixedColumn?: 'first' | 'last';
  renderAdditionalRowContent?: (item: T) => React.ReactElement;
  itemsPerPage?: number;
  defaultSort?: { columnKey: keyof T; order: 'asc' | 'desc' };
  wrapLines?: boolean;
  inline?: boolean;
  autoResetPagination?: boolean;
  disableRow?: (item: T) => boolean;
  footer?: React.ReactElement;
  rowActionsPosition?: Placement;
};

export const Table = <T extends object>(props: Props<T>): React.ReactElement => {
  const [sortedItems, onSortChanged, shouldDisplayChevron] = useSorting<Item<T>>(
    props.items,
    props.defaultSort,
  );
  const pagination = usePagination(
    sortedItems,
    props.itemsPerPage || Infinity,
    props.autoResetPagination,
  );

  return (
    <BaseTable
      {...props}
      items={pagination.items}
      onSortChanged={onSortChanged}
      shouldDisplayChevron={shouldDisplayChevron}
      paginationFooter={
        pagination.hasMultiplePages ? (
          <div className="flex justify-end">
            <Pagination {...pagination} />
          </div>
        ) : (
          <></>
        )
      }
    />
  );
};
