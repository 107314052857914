import React from 'react';

import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { allCorpsEtat, proSpecializations } from '@travauxlib/shared/src/utils/constants';

import { certifications, travailEnInterne } from 'utils/constants';

export function ProFields(): React.ReactElement {
  return (
    <div className="py-xs !bg-lighter !mb-xs">
      <h4>Filtres pro</h4>
      <div className="flex flex-wrap sm-desktop:-mx-md">
        <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
          <DropdownMultiField
            id="specializations"
            name="specializations"
            label="N'envoyer que sur"
            options={proSpecializations.map(value => ({ value, label: value }))}
          />
        </div>
        <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
          <DropdownMultiField
            id="corps-etat"
            label="Assurée pour (pro)"
            name="corpsEtat"
            options={allCorpsEtat.map(value => ({ value, label: value }))}
          />
        </div>
        <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
          <DropdownMultiField
            id="certifications"
            name="certifications"
            label="Certifications"
            options={certifications.map(value => ({ value, label: value }))}
          />
        </div>
        <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
          <DropdownMultiField
            label="Réalisé en interne"
            id="travailEnInterne"
            name="travailEnInterne"
            options={travailEnInterne}
          />
        </div>
        <div className="sm-desktop:w-3/12 sm-desktop:px-md relative mt-md">
          <DropdownMultiField
            label="Pro Profil Options"
            id="proProfileFlags"
            name="proProfileFlags"
            options={[
              { value: 'BET intégré', label: 'BET intégré' },
              { value: 'Travaille avec des architectes', label: 'Travaille avec des architectes' },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
