import React from 'react';

import classNames from 'classnames';

export const FormLabel = ({
  label,
  id,
  className = '',
}: {
  label: string | React.ReactNode;
  id?: string;
  className?: string;
}): React.ReactElement => (
  <label className={classNames('!font-bold !w-full', className)} htmlFor={id}>
    {label}
  </label>
);
