import React from 'react';

import classNames, { Argument } from 'classnames';

import { Button, IButtonSizes } from '../Buttons/Button';

export type GroupedButtonVariants = 'primary' | 'secondary';

export type Option = {
  value: string | boolean;
  label: string | React.ReactElement;
  disabled?: boolean;
  leftIcon?: React.ReactElement;
  onClick?: () => void;
};

export type Props = {
  value?: string | boolean;
  onChange: (newValue: string | boolean) => void;
  options: Option[];
  fullwidth?: boolean;
  vertical?: boolean;
  variant?: GroupedButtonVariants;
  size?: IButtonSizes;
};

export const makeVariantGroupClassnames = (
  disabled?: boolean,
  optionValue?: string | boolean,
  value: string | boolean = '',
): { [key in GroupedButtonVariants]: Argument[] } => ({
  primary: [
    '!border-l-1 !border-l-white first:!border-l-0',
    { 'bg-primary-600 !text-neutral-0': optionValue === value },
    {
      '!bg-neutral-100 !text-neutral-300': disabled,
    },
  ],
  secondary: [
    'border-r-0 last:border-r',
    { '!border-l-1 !border-l-neutral-800 first:!border-l-neutral-300': disabled },
    { 'bg-neutral-800 !text-neutral-0': optionValue === value },
  ],
});

export const ButtonGroup: React.FC<Props> = ({
  value,
  onChange,
  options,
  fullwidth,
  vertical,
  variant = 'secondary',
  size = 'sm',
}) => (
  <div
    className={classNames('whitespace-nowrap flex', fullwidth && 'grow', vertical && 'flex-col')}
  >
    {options.map(({ value: optionValue, label, disabled, leftIcon, onClick }) => (
      <Button
        className={classNames(
          '!rounded-none first:!rounded-l-xxs last:!rounded-r-xxs',
          { grow: fullwidth },
          makeVariantGroupClassnames(disabled, optionValue, value)[variant],
        )}
        key={optionValue.toString()}
        variant={variant}
        size={size}
        onClick={() => (onClick ? onClick() : onChange(optionValue))}
        disabled={disabled}
        leftIcon={leftIcon}
      >
        {label}
      </Button>
    ))}
  </div>
);
