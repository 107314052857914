import React, { MouseEventHandler } from 'react';

import classNames from 'classnames';

import { ActionLink } from './ActionLink';
import { CloseButton } from './CloseButton';
import { config } from './constants';
import { Icon } from './Icon';
import { Level } from './types';

import { GenericLink } from '../Links';

type Props = {
  level: Level;
  title?: React.ReactNode;
  actionLabel?: string;
  onClickClose?: MouseEventHandler<HTMLButtonElement>;
  href?: string;
  to?: string;
  small?: boolean;
  icon?: React.ReactElement;
  fullWidthLink?: boolean;
  className?: string;
};

export const BannerBody: React.FC<Props> = ({
  level,
  title,
  href,
  to,
  actionLabel,
  onClickClose,
  small,
  icon,
  fullWidthLink,
  className,
}) => {
  const { DefaultIcon, iconClassnames } = config[level];
  const defaultIcon = DefaultIcon && <DefaultIcon />;
  const currentIcon = icon || defaultIcon;
  const content = (
    <>
      {currentIcon && (
        <div className={classNames('flex items-center', small ? 'w-xmd h-xmd' : 'w-lg h-lg')}>
          <Icon icon={currentIcon} className={iconClassnames} small={small} />
        </div>
      )}
      <div
        className={classNames(
          { ['!text-neutral-0']: level === 'primary' },
          'w-full text-neutral-800 ml-xxs flex flex-col',
          title ? 'gap-xxs' : 'justify-center',
        )}
      >
        <div className={classNames('text-b1', { 'text-b2': small })}>{title}</div>
      </div>
    </>
  );
  return (
    <div className={classNames('flex grow items-center', className)}>
      {fullWidthLink && (to !== undefined || href !== undefined) ? (
        <GenericLink to={to} href={href} className="flex grow !no-underline items-center">
          {content}
        </GenericLink>
      ) : (
        content
      )}
      {(to !== undefined || href !== undefined) && (
        <div className="flex items-center">
          <ActionLink level={level} to={to} actionLabel={actionLabel} href={href} />
        </div>
      )}
      {onClickClose && <CloseButton level={level} onClickClose={onClickClose} small={small} />}
    </div>
  );
};
