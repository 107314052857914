import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AdminFeatures,
  ConsultationInfoSubfeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class ConsultationInfoTrackingClass extends Tracker<AdminFeatures> {
  onChantierClosed(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ChantierUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: ConsultationInfoSubfeatures.Chantier,
      event: TrackingEvents.Closed,
      properties,
    });
  }

  onChantierOpened(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ChantierUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: ConsultationInfoSubfeatures.Chantier,
      event: TrackingEvents.Opened,
      properties,
    });
  }

  onInfoChantierEdited(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ChantierUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: ConsultationInfoSubfeatures.Chantier,
      event: TrackingEvents.Opened,
      properties,
    });
  }

  onFondsReleased(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ChantierUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: ConsultationInfoSubfeatures.Fonds,
      event: TrackingEvents.Released,
      properties,
    });
  }
}

export const ConsultationInfoTracking = new ConsultationInfoTrackingClass(
  AdminFeatures.ConsultationInfo,
);
