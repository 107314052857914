import {
  AlertVariant,
  BrandVariant,
  NeutralVariant,
  ExtendedPaletteVariants,
} from '@travauxlib/shared/src/types/designSystem';

export type Variant =
  | AlertVariant
  | BrandVariant
  | Extract<NeutralVariant, 'light'>
  | Extract<ExtendedPaletteVariants, 'beige'>;

export type TagSize = 'sm' | 'md' | 'lg';

export type TagOption = {
  label: string | React.ReactElement;
  variant: Variant;
  value: string;
};

export type TagOptionWithIcon = TagOption & {
  icon: React.ReactElement;
};

export const isTagWithIcon = (option: TagOption | TagOptionWithIcon): option is TagOptionWithIcon =>
  (option as TagOptionWithIcon).icon !== undefined;
