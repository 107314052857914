import React from 'react';

import { Form } from 'react-final-form';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import { Project } from '@travauxlib/shared/src/types';

import { useAuth } from 'api/useAuth';
import { FicheProjetTracking } from 'utils/tracking/FicheProjetTracking';

type Props = {
  project: Project;
  updateProject: (project: Project) => Promise<Project>;
  children: React.ReactElement;
};

export const ProjectForm: React.FC<Props> = ({ project, updateProject, children }) => {
  const { adminUserProfile } = useAuth();

  return (
    <Form<Project>
      initialValues={project}
      initialValuesEqual={() => true}
      onSubmit={async values => {
        FicheProjetTracking.onFicheProjetInfoEdited({
          'Projet UUID': project.uuid,
          'User Role': adminUserProfile.role,
        });

        await updateProject({ ...values, comments: project.comments });

        return undefined;
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AutoSubmitForm skipValuesCompare />
          {children}
        </form>
      )}
    </Form>
  );
};
