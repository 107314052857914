import { useEffect, useRef, useState } from 'react';

import { Placement } from '@floating-ui/react';
import classNames from 'classnames';

import { useScrollStatus } from '@travauxlib/shared/src/hooks/useScrollStatus';

import { TableHeaderRow } from './TableHeaderRow';
import { TableRow } from './TableRow';
import { ColumnConfig, Item, RowAction, SizePaddingClassName } from './types';
export type { ColumnConfig } from './types';

type Props<T> = {
  className?: string;
  title?: string;
  size?: SizePaddingClassName;
  columnConfigs: ColumnConfig<T>[];
  items: Item<T>[];
  onRowClick?: (item: T) => void;
  rowActions?: (item: T) => RowAction[];
  // TODO prevent the use of fixFirstColumn if an item has additionalRowContent prop
  fixedColumn?: 'first' | 'last';
  renderAdditionalRowContent?: (item: T) => React.ReactElement;
  itemsPerPage?: number;
  defaultSort?: { columnKey: keyof T; order: 'asc' | 'desc' };
  wrapLines?: boolean;
  inline?: boolean;
  autoResetPagination?: boolean;
  disableRow?: (item: T) => boolean;
  footer?: React.ReactElement;
  rowActionsPosition?: Placement;
  currentPage?: number;
  hasMultiplePages?: boolean;
  onSortChanged?: (columnName: keyof T) => void;
  shouldDisplayChevron?: (columnKey: keyof T, direction: 'asc' | 'desc') => boolean;
  paginationFooter?: React.ReactElement;
};

export const BaseTable = <T extends object>({
  className,
  title,
  size = 'md',
  columnConfigs,
  items,
  onRowClick,
  disableRow,
  rowActions,
  fixedColumn,
  renderAdditionalRowContent,
  wrapLines,
  inline = false,
  footer,
  rowActionsPosition,
  currentPage,
  onSortChanged = () => {},
  shouldDisplayChevron = () => false,
  paginationFooter,
}: Props<T>): React.ReactElement => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | undefined>(undefined);
  const contentRef = useRef<HTMLDivElement>(null);
  const { hasHorizontalScroll } = useScrollStatus(contentRef);

  const hasExpandableRows =
    items.some(item => item.additionalRowContent) || !!renderAdditionalRowContent;

  useEffect(() => {
    setExpandedRowIndex(undefined);
  }, [currentPage]);

  return (
    <div className={classNames('bg-neutral-0 ', className)}>
      {!inline && title && <div className="text-h5 mb-md px-md">{title}</div>}
      <div className="overflow-x-auto" ref={contentRef}>
        <table className="w-full border-separate border-spacing-0">
          {!inline && (
            <thead>
              <TableHeaderRow<T>
                size={size}
                columnConfigs={columnConfigs}
                // Pas de fixage de head pour la dernière colonne, ça décale tout quand le titre est vide
                fixedColumn={fixedColumn === 'first'}
                isHorizontallyScrollable={hasHorizontalScroll}
                onSortChanged={onSortChanged}
                shouldDisplayChevron={shouldDisplayChevron}
              />
            </thead>
          )}
          <>
            {items.map((item, index) => (
              <TableRow<T>
                disableRow={disableRow}
                wrapLines={wrapLines}
                key={`row-${index}`}
                item={item}
                rowIndex={index}
                size={size}
                additionalRowContent={item.additionalRowContent}
                renderAdditionalRowContent={renderAdditionalRowContent}
                onClickExpand={() =>
                  setExpandedRowIndex(expandedRowIndex =>
                    expandedRowIndex === index ? undefined : index,
                  )
                }
                isExpanded={expandedRowIndex === index}
                columnConfigs={columnConfigs}
                rowActions={rowActions}
                fixedColumn={fixedColumn}
                hasHorizontalScroll={hasHorizontalScroll}
                hasExpandableRows={hasExpandableRows}
                onRowClick={onRowClick}
                inline={inline}
                rowActionsPosition={rowActionsPosition}
              />
            ))}
          </>
          {footer && <tfoot className="[&_td]:py-sm">{footer}</tfoot>}
        </table>
      </div>
      {paginationFooter}
    </div>
  );
};
