import React, { createRef, useContext } from 'react';

import { Props as ModalBodyProps } from './ModalBody';
import { Props as ModalFooterProps } from './ModalFooter';
import { ModalSize } from './types';

type Props = ModalBodyProps &
  ModalFooterProps & {
    title?: string | React.ReactElement;
    isTopShadowVisible: boolean;
    handleClose?: () => void;
    size: ModalSize;
    modalMaxHeight: string;
  };

const ModalContext = React.createContext<Props>({
  contentRef: createRef<HTMLDivElement>(),
  isScrollable: true,
  isTopShadowVisible: false,
  isBottomShadowVisible: false,
  isBelowDesktopSM: false,
  size: 'md',
  modalMaxHeight: '',
});

export const useModalContext = (): Props => useContext<Props>(ModalContext);

export const ModalContextWrapper: React.FC<Props & { children: React.ReactNode }> = ({
  children,
  ...props
}) => <ModalContext.Provider value={props}>{children}</ModalContext.Provider>;
