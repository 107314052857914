import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const responsablePartenariatBuilder = new AbilityBuilder<Action, Subject, Flag>();

responsablePartenariatBuilder.can('manage', 'Lead', ['isAssignedTo']);
responsablePartenariatBuilder.can('manage', 'Partner');

export const responsablePartenariat = responsablePartenariatBuilder.build();
