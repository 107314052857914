import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Form } from 'react-final-form';

import Document from '@travauxlib/shared/src/components/DesignSystem/assets/Document.svg?react';
import ReceiptOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ReceiptOutline.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import {
  IconButtonLink,
  Link,
} from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { Toggle } from '@travauxlib/shared/src/components/DesignSystem/components/Toggle';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { required } from '@travauxlib/shared/src/utils/form';

import { CHANTIER_KEY } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

import { switchSuiviChantierV2 } from '../../api/debug_suiviChantierV2';
import { useAddProvision } from '../../api/useAddProvision';
import { useToggleWithLemonwayMock } from '../../api/useToggleWithLemonwayMock';

type Props = {
  chantier: Chantier;
};

export const Debug: React.FC<Props> = ({ chantier }) => {
  const { toggleWithLemonwayMock } = useToggleWithLemonwayMock(chantier.withMockedLemonway);
  const { addProvision } = useAddProvision();

  const queryClient = useQueryClient();
  const chantierHasNegativeSignature = chantier.signatures.some(
    signature => signature.prixTotalTTC <= 0,
  );

  return (
    <>
      <div className="flex flex-col gap-xl mb-xl">
        <div className="flex flex-col gap-md">
          <Link to={`/chantiers/${chantier.slug}`}>⬅️ Go back to chantier</Link>

          <div className="flex gap-md">
            <ButtonLink
              href={`${APP_CONFIG.apiURL}/admin/refresh-chantier-transactions?slug=${chantier.slug}`}
              target="_blank"
            >
              Sync lemonway
            </ButtonLink>
            <ButtonLink href={`${APP_CONFIG.apiURL}/admin/send-adp-scheduler`} target="_blank">
              Send ADP scheduler
            </ButtonLink>
            <ButtonLink
              href={`${APP_CONFIG.apiURL}/admin/send-relance-provision-scheduler`}
              target="_blank"
            >
              Send Relance Provision scheduler
            </ButtonLink>
          </div>
          <div>
            <div>
              <Toggle
                onChange={() =>
                  switchSuiviChantierV2(chantier.slug, !chantier.enableSuiviChantier).then(() => {
                    toast.success('Ok !');
                    queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY, chantier.slug] });
                  })
                }
                label={`Mode suivi de chantier ${
                  chantierHasNegativeSignature
                    ? 'désactivé car au moins une signature négative'
                    : ''
                }`}
                checked={chantier.enableSuiviChantier}
                disabled={chantierHasNegativeSignature}
              />
            </div>
            <div>
              <Toggle
                checked={chantier.withMockedLemonway}
                onChange={() => toggleWithLemonwayMock(chantier)}
                label="Activer le mock de lemonway"
              />
            </div>
          </div>
          {chantier.withMockedLemonway && (
            <Form<{ montantProvision: number }>
              onSubmit={({ montantProvision }) => addProvision({ chantier, montantProvision })}
            >
              {({ handleSubmit, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <Card className="inline-flex" bodyClassNames="flex flex-col gap-md">
                    <h4>Ajouter une provision</h4>
                    <NumberInputField
                      name="montantProvision"
                      id="montantProvision"
                      label="Montant de la provision en centimes"
                      placeholder="Exemple: 50023 = 500.23€"
                      validate={required}
                    />
                    <Button
                      type="submit"
                      disabled={invalid || submitting}
                      loading={submitting}
                      fullwidth
                    >
                      Ajouter les SOUS
                    </Button>
                  </Card>
                </form>
              )}
            </Form>
          )}
        </div>
        <div>
          <h4>Détails</h4>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Échéancier auto</td>
                <td className="text-right">{chantier.enableEcheancierAuto ? '✅' : '❌'}</td>
              </tr>
              <tr>
                <td>Provisions demandées</td>
                <td className="text-right">
                  <EURCurrency amount={chantier.debug.totalAppelsDeProvision} forceFullPattern />
                </td>
              </tr>
              <tr>
                <td>Provisions reçues</td>
                <td className="text-right">
                  <EURCurrency amount={chantier.totalProvisions} forceFullPattern />
                </td>
              </tr>
              <tr>
                <td>Provisions attendues</td>
                <td className="text-right">
                  <EURCurrency
                    amount={chantier.debug.totalAppelsDeProvision - chantier.totalProvisions}
                    forceFullPattern
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h4>Appels de provision</h4>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>date d'effet</th>
                <th>montant</th>
                <th>statut</th>
                <th>type</th>
                <th>devis</th>
                <th>facture acompte</th>
              </tr>
              {chantier.debug.allAppelsDeProvision.map(appel => (
                <tr key={appel.uuid}>
                  <td>
                    {dayjs(appel.dateEffet).format('DD/MM/YYYY HH:mm')}
                    {dayjs(appel.dateEffet).isAfter(dayjs()) ? ' 🕐' : ''}
                  </td>
                  <td className="!text-right">
                    <EURCurrency amount={appel.montant} forceFullPattern />
                  </td>
                  <td>{appel.status}</td>
                  <td>{appel.type}</td>
                  <td>
                    <Link
                      href={`${APP_CONFIG.appURL}/pro/devis/${appel.devisToken}`}
                      title="Voir le devis"
                      target="_blank"
                      rel="noopener noreferrer"
                      icon={<Document />}
                    >
                      Devis #
                      {
                        chantier.signatures.find(s => s.devisToken === appel.devisToken)
                          ?.devisNumber
                      }
                    </Link>
                  </td>
                  <td>
                    {appel.factureAcompteToken ? (
                      <IconButtonLink
                        href={`${APP_CONFIG.appURL}/factures/${appel.factureAcompteToken}`}
                        title="Voir la facture d'acompte"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ReceiptOutline />
                      </IconButtonLink>
                    ) : (
                      'Pas de facture'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex gap-xl">
          <div className="basis-1/2">
            <h4>
              <abbr title="Calendrier des provisions attendues">Échéancier</abbr>
            </h4>
            {chantier.debug.cumulAppelsDeProvision.length === 0 ? 'Vide' : ''}
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>date</th>
                  <th>montant cumulé attendu</th>
                  <th>diff</th>
                </tr>
                {chantier.debug.cumulAppelsDeProvision.map((echeance, i) => {
                  const prev = chantier.debug.cumulAppelsDeProvision[i - 1];
                  return (
                    <tr key={echeance.dateEffet.toString()}>
                      <td>
                        {dayjs(echeance.dateEffet).format('DD/MM/YYYY')}
                        {dayjs(echeance.dateEffet).isAfter(dayjs()) ? ' 🕐' : ''}
                      </td>
                      <td className="!text-right">
                        <EURCurrency amount={echeance.montant} forceFullPattern />
                      </td>
                      <td className="!text-right">
                        (+
                        <EURCurrency
                          amount={echeance.montant - (prev?.montant || 0)}
                          forceFullPattern
                        />
                        )
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="basis-1/2">
            <h4>
              <abbr title="Emails envoyés">Relances de provision</abbr>
            </h4>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>date envoi</th>
                  <th>montant demandé</th>
                </tr>
                {chantier.debug.relancesDeProvision.map(relance => (
                  <tr key={relance.emailSentAt}>
                    <td>
                      {dayjs(relance.emailSentAt.replaceAll('[Europe/Paris]', '')).format(
                        'DD/MM/YYYY HH:mm',
                      )}
                    </td>
                    <td className="!text-right">
                      <EURCurrency amount={relance.montantDemande} forceFullPattern />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
