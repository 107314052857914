import React from 'react';

import {
  Input,
  Props as InputProps,
} from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { safeParseFloat } from '@travauxlib/shared/src/utils/parsers';

export type Props = Omit<InputProps, 'value' | 'onChange'> & {
  value?: number | string;
  max: number;
  min: number;
  fractionDigits: number;
  onChange: (value?: number) => void;
};

type State = {
  lastValidValue: string;
  displayedValue: string;
};

export class NumberInput extends React.Component<Props, State> {
  static defaultProps = {
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    fractionDigits: 2,
  };

  constructor(props: Props) {
    super(props);
    const { value: numberValue } = props;
    const value = numberValue !== undefined ? numberValue.toString() : '';

    this.state = {
      displayedValue: value,
      lastValidValue: value,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { value: newValue } = this.props;
    if (prevProps.value !== newValue && !Number.isNaN(newValue!)) {
      const valueToSet = newValue == null ? '' : newValue.toString();

      this.setState({
        displayedValue: valueToSet,
        lastValidValue: valueToSet,
      });
    }
  }

  handleChange = ({
    target: {
      value,
      validity: { badInput },
    },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange, value: propsValue } = this.props;

    this.setState(
      state => ({
        ...state,
        displayedValue: value,
        lastValidValue: badInput ? state.lastValidValue : value || '',
      }),
      () => {
        const validatedValue = this.getValidatedValue(value);
        if (validatedValue !== propsValue) {
          onChange(validatedValue);
        }
      },
    );
  };

  getValidatedValue = (targetValue: string): number | undefined => {
    const { min, max, fractionDigits } = this.props;
    const { lastValidValue } = this.state;

    const parseableValue = Number.isNaN(safeParseFloat(targetValue)) ? lastValidValue : targetValue;

    if (parseableValue) {
      const parsedValue = safeParseFloat(parseableValue);
      const roundedValue = Math.round(parsedValue * 10 ** fractionDigits) / 10 ** fractionDigits;

      return Math.min(Math.max(roundedValue, min), max);
    }

    return undefined;
  };

  render(): React.ReactElement {
    const { displayedValue } = this.state;
    const { step = 'any', min, fractionDigits, onChange, ...rest } = this.props;

    return (
      <Input
        {...rest}
        min={min}
        value={displayedValue}
        type="number"
        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
          const { key } = event;
          if (fractionDigits === 0 && (key === ',' || key === '.')) {
            event.preventDefault();
          }
          if ((key !== 'Enter' && !key.match(/[-+0-9,.]/)) || (min >= 0 && key.match(/[+-]/))) {
            event.preventDefault();
          }
          rest.onKeyPress?.(event);
        }}
        onWheel={(e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.blur()}
        nativeOnChange={this.handleChange}
        step={step}
      />
    );
  }
}
