import React from 'react';

import classNames from 'classnames';

import { ChipSize } from './types';
import { chipClassNamesByVariant } from './utils';

import { Badge } from '../Badge';
import { ColoredCircle } from '../ColoredCircle';

type Props = {
  leftIcon: React.ReactElement;
  label: string | React.ReactElement;
  helperText?: string;
  onClick: (element: HTMLDivElement | null) => void;
  onMounted?: (element: HTMLDivElement | null) => void;
  rightIcon?: React.ReactElement;
  isActive?: boolean;
  className?: string;
  size?: ChipSize;
  disabled?: boolean;
  fullColor?: boolean;
  badgeCount?: number;
};

export const ChipPicto: React.FC<Props> = ({
  leftIcon,
  rightIcon,
  onClick,
  isActive,
  label,
  helperText,
  className,
  size = 'md',
  disabled,
  fullColor = false,
  onMounted,
  badgeCount,
}) => {
  const {
    paddingClassNames,
    textContainerClassNames,
    labelClassNames,
    helperTextClassNames,
    leftIconSize,
    leftIconClassNames,
    rightIconClassNames,
  } = chipClassNamesByVariant[size];

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref) {
      onMounted?.(ref.current);
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      className={classNames(
        className,
        paddingClassNames,
        'relative',
        'inline-flex items-center',
        'bg-neutral-0 rounded-full shadow-sm',
        'relative after:content-[""] after:absolute after:border after:inset-0 after:rounded-full',
        { 'after:border-primary': isActive },
        {
          'shadow-sm active:after:border-neutral-800 active:after:border-2 active:shadow-sm hover:shadow-md':
            !fullColor,
        },
        {
          'hover:after:border-neutral-600 hover:after:border-400 active:after:border-primary active:after:border':
            fullColor,
        },
        { 'pointer-events-none bg-neutral-100 text-neutral-300': disabled },
        { 'bg-primary text-white': fullColor && isActive && !disabled },
      )}
      onClick={() => !disabled && onClick(ref.current)}
      role="button"
    >
      <ColoredCircle
        className={leftIconClassNames}
        variant={disabled ? 'light' : 'secondary'}
        size={leftIconSize}
      >
        {leftIcon}
      </ColoredCircle>
      <div className={classNames('flex', textContainerClassNames)}>
        <span className={classNames('font-bold', labelClassNames)}>{label}</span>
        <span
          className={classNames(
            disabled ? 'text-neutral-300' : 'text-neutral-600',
            helperTextClassNames,
            {
              '!text-neutral-300': disabled,
              '!text-neutral-600': !fullColor && !disabled,
              '!text-white': fullColor && isActive && !disabled,
            },
          )}
        >
          {helperText}
        </span>
      </div>
      {rightIcon && (
        <div className={classNames('flex items-center', rightIconClassNames)}>{rightIcon}</div>
      )}
      {badgeCount && badgeCount > 0 ? (
        <Badge
          className="absolute -top-xxs -right-xxs"
          size="sm"
          variant="error"
          value={badgeCount}
        />
      ) : null}
    </div>
  );
};
