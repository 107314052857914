import React from 'react';

import Warning from '@travauxlib/shared/src/components/DesignSystem/assets/Warning.svg?react';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type OwnProps = {};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

const legalInfo = {
  vatNumber: 'FR0583261097',
  siret: '83261097600017',
  creationDate: '13/09/2017',
  rcsCity: 'Bordeaux',
  capital: 2000,
  accountHolder: 'M BOURGOUIN LUDOVIC entrepreneur individuel',
  iban: 'FR7630004005540001016166072',
  bic: 'BNPAFRPPXXX',
};

const InformationRow = ({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactElement | undefined;
}): React.ReactElement => (
  <div className="flex items-center mb-sm">
    <div className="mr-xs">{label} :</div>
    {value ? (
      <div className="font-medium">{value}</div>
    ) : (
      <div className="flex items-center gap-xxs">
        <Warning className="size-md text-warning-800" />
        <span className='className="text-neutral-700'>Non renseigné</span>
      </div>
    )}
  </div>
);

export const InformationsFacturationModal: React.FC<Props> = ({ handleClose }) => (
  <ModalContent
    validateAction={{
      label: 'Fermer',
      onClick: handleClose,
    }}
    title="Informations facturation"
  >
    <div className="p-6">
      <InformationRow label="Numéro de TVA" value={legalInfo.vatNumber} />
      <InformationRow label="Siret" value={legalInfo.siret} />
      <InformationRow label="Date de création" value={legalInfo.creationDate} />
      <InformationRow label="Ville du RCS" value={legalInfo.rcsCity} />
      <InformationRow label="Statut" value={undefined} />
      <InformationRow label="Capital" value={<EURCurrency amount={legalInfo.capital} />} />
      <InformationRow label="Titulaire du compte" value={legalInfo.accountHolder} />
      <InformationRow label="IBAN" value={legalInfo.iban} />
      <InformationRow label="Code BIC/SWIFT" value={legalInfo.bic} />
      <InformationRow label="Assujeti à la tva" value="Oui" />
    </div>
  </ModalContent>
);

export const useInformationsFacturation = (): (() => void) => {
  const openModal = useOpenModal();

  return () =>
    openModal(InformationsFacturationModal, {
      closable: true,
    });
};
