import { SenderType } from '@travauxlib/shared/src/types';

import { Avatar, Props } from '.';

export type UserType = SenderType | 'other';

const variantMap = {
  'deal-owner': 'primary',
  client: 'beige',
  pro: 'lightgreen',
  other: 'blue',
} as const;

export type CommentAvatarProps = Omit<Props, 'variant'> & {
  userType: UserType;
};

export const CommentAvatar: React.FC<CommentAvatarProps> = ({ userType, ...props }) => (
  <Avatar variant={variantMap[userType]} {...props} />
);
