import React, { useState } from 'react';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Textarea } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea';
import { DevisComment, SenderType } from '@travauxlib/shared/src/types';

import { CommentHeader } from './CommentHeader';

type Props = {
  onSubmit: (text: string, comment?: DevisComment) => void;
  comment?: DevisComment;
  senderName: string;
  onClose: () => void;
  className?: string;
  senderType: SenderType;
};

export const TextareaForm: React.FC<Props> = ({
  onSubmit,
  senderName,
  onClose,
  comment,
  className,
  senderType,
}) => {
  const [text, setText] = useState(comment?.text || '');
  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await onSubmit(text, comment);
  };

  return (
    <form onSubmit={handleSubmit} className={className}>
      <CommentHeader
        senderName={senderName}
        commentDate={comment?.updatedAt}
        senderType={comment?.senderType || senderType}
        rightContent={
          <div>
            <IconButton onClick={onClose}>
              <CrossSymbol />
            </IconButton>
          </div>
        }
      />

      <div className="ml-xl mb-xxs pl-xs">
        <Textarea
          data-testid="comment"
          id="comment"
          className="mb-xs"
          label="Ajouter un commentaire"
          value={text}
          onChange={setText}
          rows={3}
          autoFocus
          onFocus={(e: React.FocusEvent<HTMLTextAreaElement>) => {
            e.target.selectionStart = e.target.value.length;
          }}
        />

        <div className="!text-right pb-xxs">
          <Button variant="secondary" className="mr-xs" onClick={onClose} size="sm">
            Annuler
          </Button>
          <Button size="sm" type="submit">
            {comment ? 'Modifier' : 'Commenter'}
          </Button>
        </div>
      </div>
    </form>
  );
};
