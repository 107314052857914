import React from 'react';

import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';

type Props = {
  children: React.ReactElement;
  disabledReasons: string[];
  proCompanyUuid: string;
};

export const DisabledReasonsTooltip: React.FC<Props> = ({
  children,
  disabledReasons,
  proCompanyUuid,
}) => (
  <>
    {disabledReasons.length > 0 ? (
      <WithTooltip
        position="bottom"
        trigger={
          <span data-tip data-for={`disabledReason-${proCompanyUuid}`}>
            {children}
          </span>
        }
      >
        <>
          {disabledReasons.map(reason => (
            <React.Fragment key={reason}>
              {reason}
              <br />
            </React.Fragment>
          ))}
        </>
      </WithTooltip>
    ) : (
      <span data-tip data-for={`disabledReason-${proCompanyUuid}`}>
        {children}
      </span>
    )}
  </>
);
