import React, { useState } from 'react';

import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import InventoryOutline from '@travauxlib/shared/src/components/DesignSystem/assets/InventoryOutline.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import PersonOutline from '@travauxlib/shared/src/components/DesignSystem/assets/PersonOutline.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import Pipedrive from '@travauxlib/shared/src/components/DesignSystem/assets/Pipedrive.svg?react';
import { ActionLink } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage/ActionLink';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';

import { getProjectURL, useSignInAsClient } from 'api/useSignInAsClient';
import { ProjectContext } from 'features/Project/api/useProjectContext';

import { ClientAccountsModal } from './ClientAccountsModal';
import { Comments } from './Comments';

const baseTabs = [
  {
    label: 'Information',
    to: 'information',
  },
  {
    label: 'Cadrage',
    to: 'cadrage',
  },
  {
    label: 'Consultation',
    to: 'consultation',
  },
  {
    label: 'Devis',
    to: 'devis',
  },
];

type Props = {
  projectContext: ProjectContext;
};

export const Header: React.FC<Props> = ({ projectContext }) => {
  const [isClientAccountsModalOpen, setIsClientAccountsModalOpen] = useState(false);
  const handleSignIn = useSignInAsClient();

  const { project, clientAccounts, chantiers } = projectContext;
  const [firstCreatedClientAccount] = clientAccounts;

  return (
    <div className="bg-neutral-0 sm-desktop:-mx-xl -mx-md -mt-md px-xl min-h-[9.5rem]">
      <div className="pt-md">
        <div className="flex justify-between items-center sm-desktop:flex-row flex-col">
          <div className="text-h3 font-bold text-neutral-800 truncate max-w-2/3">
            {project.title}
          </div>
          <div className="flex gap-md ml-md items-center sm-desktop:flex-row flex-col">
            {project.pipedriveDealUrl && (
              <ButtonLink
                leftIcon={<Pipedrive />}
                size="sm"
                variant="secondary"
                target="_blank"
                href={project.pipedriveDealUrl}
              >
                Accéder au Pipe
              </ButtonLink>
            )}
            <Button
              onClick={() =>
                handleSignIn(firstCreatedClientAccount.uuid, getProjectURL(project.uuid))
              }
              leftIcon={<PersonOutline />}
              size="sm"
            >
              Accéder à l'espace client
            </Button>
          </div>
        </div>
        <div className="flex gap-md mt-xxs text-b1 font-medium text-neutral-700 flex-col sm-desktop:flex-row">
          <div>
            <Person className="w-lg h-lg mr-xxs" />
            <span>
              {firstCreatedClientAccount.firstName} {firstCreatedClientAccount.lastName}
            </span>
          </div>
          <div>
            <Phone className="w-lg h-lg mr-xxs" />
            <span>{firstCreatedClientAccount.phoneNumber}</span>
          </div>
          <div>
            <Envelope className="w-lg h-lg mr-xxs" />
            <span>{firstCreatedClientAccount.email}</span>
          </div>
          <ActionLink
            actionLabel="Gestion des contacts"
            onClick={() => setIsClientAccountsModalOpen(prevState => !prevState)}
          />
        </div>
      </div>
      <div className="flex text-b1 font-bold sm-desktop:flex-row flex-col-reverse pt-md">
        <Tabs
          items={
            chantiers?.length
              ? [
                  ...baseTabs,
                  {
                    label: 'Chantier',
                    to: chantiers.length === 1 ? `chantier/${chantiers[0].slug}` : 'chantier',
                    isActive: location => location.pathname.includes('chantier'),
                  },
                ]
              : baseTabs
          }
          hideContainerBottomBorder
          className="flex-1"
        />
        <div className="flex flex-col sm-desktop:flex-row w-fit items-start">
          <ButtonLink
            to="actions"
            variant="tertiary"
            className="!p-sm"
            leftIcon={<InventoryOutline />}
          >
            Valider une action du client
          </ButtonLink>
          <Comments project={project} />
        </div>
      </div>
      <ClientAccountsModal
        isOpen={isClientAccountsModalOpen}
        projectContext={projectContext}
        onClose={() => setIsClientAccountsModalOpen(false)}
      />
    </div>
  );
};
