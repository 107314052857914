import React from 'react';

import classNames from 'classnames';

type Props = {
  small?: boolean;
  icon: React.ReactElement;
  className: string;
};

export const Icon: React.FC<Props> = ({ className, icon, small }) => (
  <span
    className={classNames(
      'shrink-0 flex items-center',
      className,
      small ? 'w-xmd h-xmd' : 'w-lg h-lg',
    )}
  >
    {icon}
  </span>
);
