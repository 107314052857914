import React, { useLayoutEffect, useRef } from 'react';

import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';

import {
  InputErrorText,
  InputHelperText,
  makeInputContainerClassnames,
  makeInputLabelClassnames,
  makeInputMainClassnames,
} from '../Input/commons';

type CustomTextareaProps = {
  id: string;
  value: string;
  onChange?: (currentValue: string) => void;
  label?: string | React.ReactElement;
  helperText?: string;
  error?: string;
  className?: string;
  minRows?: number;
  maxRows?: number;
  renderingOptions?: {
    hideErrorText?: boolean;
    hideHelperText?: boolean;
    inputClassName?: string;
    inputContainerClassName?: string;
  };
};

export type Props = CustomTextareaProps &
  Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange' | 'prefix' | 'onResize'>;

export const Textarea: React.FC<Props> = ({
  id,
  label,
  value,
  placeholder,
  onChange,
  helperText,
  error,
  disabled,
  className,
  renderingOptions,
  hidden,
  ...rest
}) => {
  const hasLabel = !!label;

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useLayoutEffect(() => {
    // setSelectionRange est utilisé pour positionner le curseur à la fin du texte
    inputRef.current?.setSelectionRange(inputRef.current?.value.length, value.length);
  }, []);

  return (
    <div className={className}>
      <div
        className={classNames(
          {
            [makeInputContainerClassnames({ disabled, error })]: !hidden,
          },
          renderingOptions?.inputContainerClassName,
          '!pr-0',
        )}
      >
        <TextareaAutosize
          className={classNames(
            makeInputMainClassnames({ disabled, hasLabel }),
            renderingOptions?.inputClassName,
            '!mr-0 !resize-y',
          )}
          ref={inputRef}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={event => onChange?.((event.target as HTMLTextAreaElement).value)}
          disabled={disabled}
          hidden={hidden}
          {...(rest as any)}
        />
        {label && (
          <label className={makeInputLabelClassnames({ value, disabled })} htmlFor={id}>
            {label}
          </label>
        )}
      </div>
      {!renderingOptions?.hideErrorText && <InputErrorText error={error} />}
      {!renderingOptions?.hideHelperText && <InputHelperText helperText={helperText} />}
    </div>
  );
};
