import _clamp from 'lodash/clamp';
import _range from 'lodash/range';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { PaginationType } from '@travauxlib/shared/src/hooks/usePagination';

import { Button } from '../Buttons/Button';

const MAX_SHOW_PAGES = 7;

export const getTruncatedPages = (currentPage: number, totalPages: number): (number | '...')[] => {
  if (totalPages <= MAX_SHOW_PAGES) {
    return _range(totalPages).map(i => i + 1);
  }

  const middleStart = _clamp(currentPage - 1, 3, totalPages - 4);

  return [
    1,
    currentPage >= 4 ? '...' : 2,
    ..._range(middleStart, middleStart + 3),
    currentPage >= totalPages - 2 ? totalPages - 1 : '...',
    totalPages,
  ];
};

type PaginationProps<T> = PaginationType<T> & {
  showTotalRecords?: boolean;
};

export const Pagination = <T extends object>({
  totalElements,
  nextPage,
  previousPage,
  canGoPrevious,
  canGoNext,
  firstItemIndex,
  lastItemIndex,
  showTotalRecords = true,
  currentPage,
  totalPages,
  setCurrentPage,
}: PaginationProps<T>): React.ReactElement => {
  const totalPagesTruncated = getTruncatedPages(currentPage, totalPages);

  return (
    <div className="flex items-center w-fit">
      {showTotalRecords && (
        <div className="text-b2 text-neutral-700 mr-xs my-[6px] min-w-[5.9375rem] text-right">
          {firstItemIndex} - {lastItemIndex} sur {totalElements}
        </div>
      )}
      <div className="flex items-center">
        <Button
          leftIcon={<ChevronLeft />}
          variant="secondary"
          size="sm"
          disabled={!canGoPrevious}
          onClick={previousPage}
          className="mr-xs"
          key="previous"
          data-testid="previous"
        />
        <div className="flex gap-xs">
          {totalPagesTruncated.map((page, index) => {
            const isActive = page === currentPage;

            if (typeof page === 'number') {
              return (
                <Button
                  key={`${page}-${index}`}
                  data-testid={`page-${page}`}
                  size="sm"
                  className="w-xl"
                  variant={isActive ? 'primary' : 'tertiary'}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Button>
              );
            }

            return (
              <span
                key={index}
                className="text-b2 text-neutral-700 w-xl h-xl flex items-center justify-center"
              >
                {page}
              </span>
            );
          })}
        </div>
        <Button
          leftIcon={<ChevronRight />}
          variant="secondary"
          size="sm"
          disabled={!canGoNext}
          onClick={nextPage}
          className="ml-xs"
          key="next"
          data-testid="next"
        />
      </div>
    </div>
  );
};
