import React from 'react';

import classNames from 'classnames';

import { SizePaddingClassName } from './types';
import { makeCommonRowClassName, sizePaddingColumnClassName } from './utils';

type Props = {
  additionalRowContent?: React.ReactElement;
  onRowClick: () => void;
  isRowClickable: boolean;
  columnCount: number;
  size: SizePaddingClassName;
};

export const AdditionalRowContent: React.FC<Props> = ({
  onRowClick,
  additionalRowContent,
  isRowClickable,
  columnCount,
  size,
}) => (
  <tr className={makeCommonRowClassName(isRowClickable)} onClick={onRowClick}>
    <td className={classNames(sizePaddingColumnClassName[size])} colSpan={columnCount}>
      {additionalRowContent}
    </td>
  </tr>
);
