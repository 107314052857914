import { createConsultation } from './createConsultation';
import { createLeadAndCompleteConfigurateur } from './createLeadAndCompleteConfigurateur';

import { CreateAutoGeneratedDevis } from '../gdm_steps/Configurateur/CreateAutoGeneratedDevis';
import { ValidateConfigurateur } from '../gdm_steps/Configurateur/ValidateConfigurateur';

// eslint-disable-next-line
export const createLeadAndCompleteConfigurateurAndConsultation = () =>
  createLeadAndCompleteConfigurateur()
    .next(new ValidateConfigurateur())
    .next(new CreateAutoGeneratedDevis())
    .nextFlow(createConsultation());
