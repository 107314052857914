import classNames from 'classnames';

import { ColumnConfig, SizePaddingClassName } from './types';
import { sizePaddingColumnClassName, verticalFirstColShadowClassName } from './utils';

import Switch from '../../assets/Switch.svg?react';
import SwitchDown from '../../assets/SwitchDown.svg?react';
import SwitchUp from '../../assets/SwitchUp.svg?react';
import { IconButton } from '../Buttons/IconButton';

type Props<T> = {
  columnConfigs: ColumnConfig<T>[];
  size: SizePaddingClassName;
  shouldDisplayChevron: (columnKey: keyof T, direction: 'asc' | 'desc') => boolean;
  onSortChanged: (sortKey: keyof T) => void;
  fixedColumn?: boolean;
  isHorizontallyScrollable?: boolean;
};

export const TableHeaderRow = <T extends object>({
  columnConfigs,
  size,
  shouldDisplayChevron,
  onSortChanged,
  fixedColumn,
  isHorizontallyScrollable,
}: Props<T>): React.ReactElement => (
  <tr>
    {columnConfigs.map(config => {
      if (!config.columnKey && !config.name) {
        return null;
      }

      const columnKey = config.columnKey;
      const isSortable = !config.hideSort;
      const isSortedByAsc = columnKey ? shouldDisplayChevron(columnKey, 'asc') : true;
      const isSortedByDesc = columnKey ? shouldDisplayChevron(columnKey, 'desc') : false;
      const isUnsorted = !isSortedByAsc && !isSortedByDesc;

      return (
        <td
          className={classNames(
            { 'cursor-pointer': isSortable },
            'text-b2 font-medium bg-neutral-0',
            sizePaddingColumnClassName[size],
            {
              [verticalFirstColShadowClassName]: fixedColumn && isHorizontallyScrollable,
            },
            config.className,
          )}
          key={config.name}
          onClick={() => isSortable && columnKey && onSortChanged(columnKey)}
        >
          <div className="flex items-center">
            {config.name}
            {isSortable && (
              <IconButton className="ml-xxs flex-shrink-0" size="sm">
                {isSortedByAsc && <SwitchUp data-testid="sorted-asc" />}
                {isSortedByDesc && <SwitchDown data-testid="sorted-desc" />}
                {isUnsorted && <Switch data-testid="unsorted" />}
              </IconButton>
            )}
          </div>
        </td>
      );
    })}
  </tr>
);
