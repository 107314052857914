import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Person } from '@travauxlib/shared/src/types';

import { defineAbilityFor } from 'lib/ability/defineAbilityFor';

import { PermissionItem } from './PermissionItem';

type Props = {
  person: Person;
};

export const PermissionCard: React.FC<Props> = ({ person }) => {
  const { role } = person;
  const ability = defineAbilityFor(role);

  return (
    <Card className="h-full">
      <div className="flex justify-between items-center">
        <h4 className="mb-md font-bold">Permissions</h4>
      </div>

      <h5 className="mb-sm font-bold">Chantier</h5>
      <div className="list-none pl-0 grid grid-cols-12 mb-md">
        <PermissionItem
          hasRight={ability.can('useAdvancedActions', 'Chantier')}
          label="Modification de l'attribution du suivi de chantier"
        />
        <PermissionItem
          hasRight={ability.can('useAdvancedActions', 'Chantier')}
          label="Modifier les informations de chantier"
        />
        <PermissionItem
          hasRight={ability.can('cancelSignature', 'Chantier')}
          label="Annulation d'une signature"
        />
        <PermissionItem
          hasRight={ability.can('changeCommission', 'Chantier')}
          label="Modifier le montant de commission"
        />
        <PermissionItem
          hasRight={ability.can('toggleRelance', 'Chantier')}
          label="Activer / Désactiver les relances pour un chantier "
        />
        <PermissionItem
          hasRight={ability.can('changeInternalAffectation', 'Chantier')}
          label="Changer l’affectation interne à un chantier"
        />
        <PermissionItem
          hasRight={ability.can('open', 'Chantier')}
          label="Ouverture d’un chantier"
        />
        <PermissionItem
          hasRight={ability.can('close', 'Chantier')}
          label="Fermeture d’un chantier"
        />
        <PermissionItem
          hasRight={ability.can('activateSuiviRenforce', 'Chantier')}
          label="Activer le suivi financier renforcé"
        />
        <PermissionItem
          hasRight={ability.can('createAppelPaiement', 'Chantier')}
          label="Créer un appel de paiement"
        />
        <PermissionItem
          hasRight={ability.can('validateAppelDePaiement', 'Chantier')}
          label="Valider un appel de paiement"
        />
      </div>
      <h5 className="mb-sm font-bold">Flux financiers</h5>
      <div className="list-none pl-0 grid grid-cols-12 mb-md">
        <PermissionItem
          hasRight={ability.can('ignoreTransaction', 'Chantier')}
          label="Ignorer une transaction dans l’historique"
        />
        <PermissionItem
          hasRight={ability.can('cancelAppelDePaiement', 'Chantier')}
          label="Annulation d’un appel de paiement"
        />
        <PermissionItem
          hasRight={ability.can('collectServiceHemea', 'Chantier')}
          label="Encaissement des prestations"
        />
        <PermissionItem
          hasRight={ability.can('changeAmountAppelDePaiement', 'Chantier')}
          label="Modifier le montant d’un appel de paiement"
        />
        <PermissionItem
          hasRight={ability.can('libererFonds', 'Chantier')}
          label="Libérer les fonds en suivi financier renforcé"
        />
      </div>
      <h5 className="mb-sm font-bold">Leads</h5>
      <div className="list-none pl-0 grid grid-cols-12 mb-sm">
        <PermissionItem
          hasRight={ability.can('manage', 'Lead')}
          label="Peut accéder à l’ensemble des leads"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Lead', ['isAssignedTo'])}
          label="Peut accéder à ses leads"
        />
        <PermissionItem
          hasRight={ability.can('manageAttributionRules', 'Lead', ['isAssignedTo'])}
          label="Modification des règles d’attribution"
        />
        <PermissionItem
          hasRight={ability.can('manageAttribution', 'Lead', ['isAssignedTo'])}
          label="Modifier l’attribution du lead"
        />
      </div>
      <h5 className="mb-sm font-bold">Partenaires</h5>
      <div className="list-none pl-0 grid grid-cols-12 mb-sm">
        <PermissionItem
          hasRight={ability.can('manage', 'Partner')}
          label="Pouvoir créer une organisation partenaire"
        />
      </div>
      <h5 className="mb-sm font-bold">Entreprise</h5>
      <div className="list-none pl-0 grid grid-cols-12 mb-sm">
        <PermissionItem
          hasRight={ability.can('manage', 'Company', ['isCommission'])}
          label="Modifier le mode de calcul de la commission"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Activer / Désactiver la base de prix hemea pour un pro"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company', ['isEmailVerified'])}
          label="Modifier le statut de vérification de l’email"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Désactivation de la numérotation automatique"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Activation du Site Vitrine"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Permettre à une entreprise de payer par RIB"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Permettre de payer l’abonnement par carte bleue"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label=" Modifier le plan 'Club Pro'"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Modifier la date de fin de période d’essai 'Club Pro'"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Activer l’option “Gratuit illimité”"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Activer le prélévement automatique"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Company')}
          label="Assigner un CDPL en tant que recruteur"
        />
      </div>
      <h5 className="mb-sm font-bold">Utilisateurs internes</h5>
      <div className="list-none pl-0 grid grid-cols-12 mb-sm">
        <PermissionItem
          hasRight={ability.can('manage', 'Person', ['isEditRecruteur'])}
          label="Assigné un CDPL en tant que “recruteur”"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Person')}
          label="Edition de profil utilisateur interne"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Person')}
          label="Voir l’équipe Admin dans le trombinoscope"
        />
        <PermissionItem
          hasRight={ability.can('manage', 'Person')}
          label="Changement d’équipe d’un utillisateur interne"
        />
      </div>
    </Card>
  );
};
