import React, { CSSProperties } from 'react';

import classNames from 'classnames';

export const layoutGridMargins = 'mx-md sm-desktop:mx-lg md-desktop:mx-xl lg-desktop:mx-[160px]';
export const layoutGridEC3Margins = 'mx-0 sm-desktop:mx-lg md-desktop:mx-xl lg-desktop:mx-[160px]';
export const layoutGridNegatedLeftMargins =
  '-ml-md sm-desktop:-ml-lg md-desktop:-ml-xl lg-desktop:-ml-[160px]';

interface Props {
  className?: string;
  children?: React.ReactElement | React.ReactElement[] | string;
  style?: CSSProperties;
  id?: string;
  ec3?: boolean;
}
export const LayoutGrid: React.FC<Props> = ({ children, className, style, id, ec3 = false }) => (
  <div
    className={classNames(
      className,
      'grid grid-cols-4 gap-x-md lg-desktop:gap-x-xl sm-desktop:grid-cols-12 tablet:grid-cols-8',
      ec3 ? layoutGridEC3Margins : layoutGridMargins,
    )}
    style={style}
    id={id}
  >
    {children}
  </div>
);
