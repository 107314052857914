import { useQuery } from '@tanstack/react-query';

import { ChantierListItem } from '@travauxlib/shared/src/features/Chantiers/types';
import { ClientAccount, Project } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { Consultation, ConsultationInfo, Devis } from 'types';

export type ProjectContext = {
  project: Project;
  clientAccounts: ClientAccount[];
  chantiers: ChantierListItem[];
  consultations: Consultation[];
  consultationInfoList: ConsultationInfo[];
  prechiffrages: Devis[];
};

export const PROJECT_KEY = 'project';

export const useProjectContext = (
  uuid: string,
): {
  isLoading: boolean;
  projectContext?: ProjectContext;
} => {
  const { isLoading, data, fetchStatus } = useQuery<ProjectContext>({
    queryKey: [PROJECT_KEY, uuid],
    queryFn: () => request(`${APP_CONFIG.apiURL}/admin/projects/${uuid}`),
  });

  return { isLoading: isLoading && fetchStatus !== 'idle', projectContext: data };
};
