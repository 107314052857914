import React from 'react';

import { Dayjs } from 'dayjs';

import { DocumentTag } from './api/domain/client/DocumentTag';
import { BaseDePrixIdentifier } from './baseDePrix';
import { Company, CompanyType, Insurance } from './company';

import { OuvrageIdentifier } from '../features/Configurateur/utils/ouvrages';
import { Consent, ConsentValue } from '../utils/consents/constants';
import { FactureSoldeTotals } from '../utils/facture/getFactureSoldeTotals';

export type Environments = 'development' | 'staging' | 'production' | 'test';
export type { CompanyType } from './company';

export type ChiffreAffairesDeclare = 'less_100K' | '100_400K' | '400_800K' | 'more_800K';

type AffiliateMetadata = { source: string; campaign: string; subcampaign: string };

export type ConsultationOfferConfiguration = {
  id: number;
};

export type AcquisitionMetadata = {
  ip: string;
  conversionButton?: string;
  conversionPage?: string;
  firstSessionTargetUrl?: string;
  firstSessionReferrer?: string;
  firstSessionDate?: string;
  firstSessionAffiliate?: AffiliateMetadata;
  lastSessionTargetUrl?: string;
  lastSessionReferrer?: string;
  lastSessionDate?: string;
  lastSessionAffiliate?: AffiliateMetadata;
};

export type DownloadableFile = {
  uuid: string;
  name: string;
  downloadUrl: string;
  imgixUrl?: string;
  createdAt: string;
};

export type DownloadableFileWithTag = {
  tag: DocumentTag;
} & DownloadableFile;

export type GooglemapsLocation = {
  lat: number;
  lng: number;
};

export type GooglemapsAddressTypes = 'route' | 'locality' | 'postal_code' | 'country';

export type GooglemapsAddress = {
  types: string[];
  geometry: {
    location: GooglemapsLocation;
  };
  place_id?: string;
  formatted_address: string;
  address_components: {
    types: GooglemapsAddressTypes[];
    long_name: string;
    short_name: string;
  }[];
};

export type ResolvedOuvrage = {
  uuid: string;
  designation: string;
  localisation?: string;
  devisItems: Ligne[];
  status?: LigneStatus;
  isRemainingOuvrages?: boolean;
  lotLabel?: string;
};

export type S3File = {
  key: string;
  name: string;
  uuid: string;
  downloadUrl: string;
};

export type GooglemapsTypes = 'geocode' | '(regions)' | 'address' | 'postal_code' | 'locality';

export enum DevisStatuses {
  draft = 'draft',
  sent = 'sent',
  signed = 'signed',
  rejected = 'rejected',
  canceled = 'canceled',
  to_be_revised = 'to_be_revised',
  sent_hemea = 'sent_hemea',
  rejected_hemea = 'rejected_hemea',
  to_be_revised_hemea = 'to_be_revised_hemea',
}

export enum FactureStatuses {
  draft = 'draft',
  awaiting_payment = 'awaiting_payment',
  paid = 'paid',
}

export type DevisFactureStatuses = DevisStatuses | FactureStatuses;

export enum Gamme {
  primo = 'primo',
  confort = 'confort',
  premium = 'premium',
  exclusif = 'exclusif',
}

export enum TypeSuivi {
  CustomerCare = 'customer_care',
  TravauxPlanner = 'travaux_planner',
  Architecte = 'architecte',
  TravauxPlannerWithService = 'travaux_planner_with_service',
  ServiceHemea = 'service_hemea',
}

export type Address = {
  address?: string;
  city?: string;
  postalCode?: string;
};

export type Deal = {
  uuid: string;
  customerName: string;
  customerEmail: string;
  billingAddress: Address;
  chantierAddress: Address;
  isHemea: boolean;
  dealOwnerFullname?: string;
  pourcentageTP: number;
};

export type ProClient = {
  name: string;
  email?: string;
  billingFormattedAddress?: string;
};

export type DevisCustomization = {
  devisExampleToken?: string;
  hasClosedBanner?: boolean;
  textColor?: string;
  logoSize?: string;
  titleFontStyle?: string;
  subTitleFontStyle?: string;
  bodyFontStyle?: string;
  headerElementsPosition?: string;
};

export type SenderType = 'pro' | 'client' | 'deal-owner';

export type SenderNames = { [K in SenderType]: string };

export type DevisComment = {
  devisToken: string;
  text: string;
  senderType: SenderType;
  uuid: string;
  targetUuid: string;
  createdAt?: string;
  updatedAt: string;
  threadUuid: string;
  validated?: boolean;
  sent?: boolean;
};

export type GlobalComment = Omit<DevisComment, 'targetUuid'>;

export type DevisComments = {
  [K: string]: DevisComment[];
};

export type DureeValidite = {
  quantity: number;
  unit: 'jour' | 'semaine' | 'mois';
};

// not very clean, typings should be improved
export type Devis = Omit<Prestations, 'replacedSignedDevisToken'> & {
  date: string;
  isFromConfiguration?: boolean;
  isPreChiffrage: boolean;
  sentAt?: string;
  status: DevisStatuses;
  version: number;
};

export type Facture = Prestations & {
  dateEmission?: string;
  dateFinPrestation?: string;
  delaiPaiement: number;
  introductionLetter?: string;
  status: FactureStatuses;
  viewCount?: number;
  proDevisToken?: string;
  proUserUuid?: string;
  isSituation: boolean;
  isCloture: boolean;
  previousFacturesAmount: number;
  avoirFromFactureToken?: string;
  linkedFactures: Pick<
    Facture,
    'lots' | 'prixTotalHT' | 'prixTotalTTC' | 'status' | 'montantsTVA'
  >[];
  paidAt?: string;
  compensationType?: CompensationType;
};

export type Signature = {
  uuid: string;
  customerName: string;
  devisTitle: string;
  dealUuid: string;
  devisNumber: string;
  customerEmail: string;
  devisToken: string;
  prixTotalHT: number;
  prixTotalTTC: number;
  dateSignature: string;
  typeSuivi: TypeSuivi;
  isTS: boolean;
  devisLink: string;
  montantAcompte: number;
  tauxCommission: number;
  montantCommissionTTC: number;
  montantTravauxPlannerTTC: number;
  montantServiceHemeaTTC: number;
  montantAPayerTTC?: number;
  chantierAddress?: Address;
  proCompanySlug: string;
  proCompanyName: string;
  proFirstNameRepresentantLegal: string;
  proLastNameRepresentantLegal: string;
  proEmail: string;
  axonautTravauxPlannerInvoiceId?: string;
  axonautArchiInterneInvoiceId?: string;
  axonautServiceHemeaInvoiceId?: string;
  axonautCommissionInvoiceId?: string;
  ownerEmail?: string;
  pipedriveDealUrl?: string;
  deletedAt?: string;
  deletedBy?: string;
  compensationType?: CompensationType;
};

export type ProRegistration = {
  uuid: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  statutEntreprise: string;
  status: 'A traiter' | 'Adressable' | 'Lost';
  companyType: CompanyType;
  chiffreAffairesDeclare: ChiffreAffairesDeclare;
  deviceFirstPageView?: string;
  createdAt: string;
  metadata: AcquisitionMetadata;
  lostReason?: string;
  acquisitionLabel?: string;
  pipedriveDealUrl?: string;
  otherInterest?: string;
  isEmailVerified: boolean;
  proCompanySlug?: string;
  siret?: string;
  companyName?: string;
  numeroTVA?: string;
  rcs?: string;
  creationDate?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  capital?: number;
  firstNameRepresentantLegal?: string;
  lastNameRepresentantLegal?: string;
  proCompanyUuid: string;
  chiffreAffaires?: string;
  isLead?: boolean;
  calendly?: Meeting;
  legalState?: string;
  websites?: {
    personalWebsite?: string;
    linkedin?: string;
  };
  financials?: {
    lastSales?: number;
    lastStaff?: number;
  };
  internalComment?: string;
  activity?: CompanyActivity;
  interventionZone?: [];
  companyCreationDate?: string;
  isDemandeChantier: boolean;
};

export type ClientAccount = {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  optin?: boolean;
  phoneNumber?: string;
  passwordConfirmationToken?: string;
  createdAt: Dayjs;
  hemeaEmployee?: string;
  hasSeenOnboardingRevision: boolean;
};

export type FournitureReference = {
  fournitureIdentifier: string;
  designation: string;
  hemeaBrand: string;
  description?: string;
  prixHT: number;
  images: string[];
  url: string;
};

export type ModalitePaiement = {
  pourcentage: number;
  label: string;
  montant?: number;
  isLabelEditable?: boolean;
  isValueEditable?: boolean;
};

export type ModaliteProvision = {
  pourcentage: number;
  label: string;
  montant?: number;
};

export type FacebookPixelUserInfo = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

export type RevisedDevis = {
  lots: Lot[];
  locations: DevisLocation[];
};

export type GarbageCenter = {
  category: string;
  name: string;
  address: string;
  customCategory?: string;
};

export type ProContext = {
  attachments: Attachment[];
  comments?: DevisComments;
  company: Company;
  contractors: DevisContractor[];
  customerSignature?: string;
  customization: DevisCustomization;
  dateDebut?: string;
  dateFin?: string;
  dateSignature?: string;
  dateVisiteTechniquePrealable?: string;
  dureeValidite?: DureeValidite;
  deal: Deal;
  enableYousign: boolean;
  hasSignedDevisPdf: boolean;
  iban?: string;
  insurances: Insurance[];
  introductionLetter?: string;
  isReviewed: boolean;
  isRGE: boolean;
  isTS: boolean;
  modalitesPaiement: ModalitePaiement[];
  modalitesProvision: ModaliteProvision[];
  montantAcompte: number;
  proCompanyCertificationsRGE: CertificationRGE[];
  proMediateurConsommation?: MediateurConsommation;
  rejectionReason?: string;
  referenceVirementAcompteAttendue?: string;
  revisedDevis?: RevisedDevis;
  rib?: string;
  signature?: Signature;
  signe: boolean;
  globalComment?: Omit<DevisComment, 'targetUuid'>;
  additionalInfos?: string;
  projectUuid?: string;
  withDefaultCGV: boolean;
  replacedSignedDevisToken?: string;
};

export type Prestations = {
  archived?: boolean;
  chantierAddress?: string;
  createdAt: string;
  commissionRate?: number;
  customerName: string;
  customerPhoneNumber?: string;
  dateSignature?: string;
  defaultMargin: number;
  defaultTauxTVA: number;
  headerText?: TextItem;
  highlightedUuids?: string[];
  isAvoir?: boolean;
  isHighlighted?: boolean;
  lastModifiedAt: string;
  locations: DevisLocation[];
  lots: Lot[];
  lotsByLocations: Lot[];
  montantDejaPaye?: number;
  montantMargeTotale?: number;
  montantRemise: number;
  montantRestantAPayer?: number;
  montantsTVA: MontantTVA[];
  numero: string;
  ouvrages?: Ouvrage[];
  primes: Prime[];
  prixTotalMainOeuvreHT?: number;
  prixTotalFournitureHT?: number;
  prixTotalFreeTTC?: number;
  prixTotalHT: number;
  prixTotalHTAvantRemise: number;
  prixTotalTTC: number;
  prixTotalTTCAvantPrimes: number;
  prixTotalTTCAvantRemise: number;
  remises: Remises;
  replacedSignedDevisToken?: string;
  showFournitures: boolean;
  showMainOeuvre: boolean;
  title: string;
  token: string;
  transferred?: boolean;
  globalComment?: GlobalComment;
};

export type Lot = {
  isDraft?: boolean;
  label: string;
  items: DevisItem[];
  prixTotalHT?: number;
  uuid: string;
  facturationPercentage?: number;
  locationUuid?: string;
};

export type UnitValue = 'unitaire' | 'm' | 'm2' | 'm3' | 'ml' | 'h' | 'j' | 'kg' | 'forfait';
export type UnitLabel = 'unité' | 'm' | 'm²' | 'm³' | 'ml' | 'heure' | 'jour' | 'kg' | 'forfait';

export type Unit = {
  label: UnitLabel;
  value: UnitValue;
  priceUnit: string;
  unitaire?: boolean;
  shortLabel?: string;
};

export type OriginType = 'batiprix' | 'self' | 'hemea';

export type HemeaItem = {
  id?: undefined;
  normalizedIdentifier: string;
  quantity: number;
};

export type CustomItem = {
  id: number;
  normalizedIdentifier?: undefined;
  quantity: number;
};

export type BaseDePrixItem = HemeaItem | CustomItem;

export type BaseDePrixLigne = {
  id?: number;
  normalizedIdentifier?: BaseDePrixIdentifier;
  lot: string;
  category?: string;
  categoryLabel?: OuvrageIdentifier;
  designation: string;
  normalizedForSearchDesignation?: string;
  description?: string;
  prixUnitaireMainOeuvreHT?: number;
  prixPublicFournitureHT?: number;
  prixPrimoFournitureHT?: number;
  prixConfortFournitureHT?: number;
  prixPremiumFournitureHT?: number;
  prixExclusifFournitureHT?: number;
  prixUnitaireHT: number;
  prixAchat?: number;
  unite: UnitValue;
  uniteLabel?: string;
  tauxTVA: number;
  quantityComputerId?: QuantityComputerId;
  defaultLocation?: DefaultLocation;
  priority?: number;
  fournitureReferences?: FournitureReference[];
  proUserUuid?: string;
  lastModifiedAt?: string;
  favorite?: boolean;
  origin: OriginType;
  mainOeuvreIdentifier?: string;
  mainOeuvreId?: number;
  mainOeuvres?: BaseDePrixItem[];
  resolvedMainOeuvres?: [MainOeuvre, number][];
  fournitures?: BaseDePrixItem[];
  resolvedFournitures?: [Fourniture, number][];
  materiaux?: BaseDePrixItem[];
  resolvedMateriaux?: [Materiau, number][];
};

export type MainOeuvre = {
  id?: number;
  normalizedIdentifier?: string;
  designation: string;
  prixAchat: number;
  prixAchatJournee: number;
  marge: number;
};

export type Materiau = {
  id?: number;
  normalizedIdentifier?: string;
  designation: string;
  description?: string;
  prixAchat: number;
  decoupe?: number;
  marge: number;
  unite: UnitValue;
};

export type Fourniture = {
  id?: number;
  normalizedIdentifier?: string;
  designation: string;
  description?: string;
  prixAchat: number;
  decoupe?: number;
  unite: UnitValue;
};

export type MontantTVA = {
  taux: number;
  base: number;
  montant: number;
};

export type LigneCategory = {
  label: string;
  lignes: BaseDePrixLigne[];
};

export type QuantityComputerId =
  | 'sols'
  | 'murs'
  | 'perimetre'
  | 'entree'
  | 'cuisine'
  | 'salle_de_bain'
  | 'wc'
  | 'cloison'
  | 'non_applicable';

export type DefaultLocation = 'entree' | 'cuisine' | 'salle_de_bain' | 'wc' | 'au_global';

export enum LigneStatus {
  Option = 'option',
  Free = 'free',
  ToEdit = 'to_edit',
  Modified = 'modified',
  Canceled = 'canceled',
}

export type FournitureLigne = {
  uuid: string;
  url?: string;
  images: string[];
  designation?: string;
  description?: string;
  prixHT: number;
  showUrlOnDevis?: boolean;
  hemeaBrand?: string;
};

interface DevisItemLike {
  uuid: string;
  type: 'pagebreak' | 'text' | 'ligne';
  lotUuid: string;
}

export interface TextItem extends DevisItemLike {
  text?: string;
  type: 'text';
}

export interface PageBreakItem extends DevisItemLike {
  type: 'pagebreak';
}

export type Ouvrage = {
  uuid?: string;
  designation: string;
  localisation?: string;
  devisItems: string[];
  priority: number;
};

export type DevisItem = Ligne | TextItem | PageBreakItem;

export type ComparisonStatus = 'added' | 'missing' | 'unchanged' | 'modified';

export interface Ligne extends DevisItemLike {
  linkedToDevisItemUuid?: string;
  description?: string;
  designation: string;
  fournitureLignes?: FournitureLigne[];
  isDraft?: boolean;
  isHighlighted?: boolean;
  locations: LigneLocation[];
  lot?: string;
  marge?: number;
  montantTVA: number;
  normalizedIdentifier?: BaseDePrixIdentifier;
  priority?: number;
  prixAchat: number;
  prixHT: number;
  prixTTC: number;
  prixPublicFournitureHT?: number;
  prixMainOeuvreHT?: number;
  prixMateriauxHT?: number;
  prixUnitaireHT: number;
  proBaseDePrixId?: string;
  quantite: number;
  status?: LigneStatus;
  tauxTVA: number;
  type: 'ligne';
  unite: UnitValue;
  uniteLabel?: string;
  threadUuid?: string;
  compare?: Ligne;
  comparisonStatus?: ComparisonStatus;
}

export type LigneLocation = {
  uuid: string;
  quantite: number;
};

export type TypeLocation =
  | 'Autre'
  | 'Bureau'
  | 'Chambre'
  | 'Cuisine'
  | 'Dégagement'
  | 'Dressing'
  | 'Entrée'
  | 'Pièce principale'
  | 'Salle à manger'
  | "Salle d'eau"
  | 'Salle de bain'
  | 'Salon'
  | 'Séjour'
  | 'Terrasse'
  | 'WC';

export type DevisLocation = {
  uuid: string;
  label: string;
  surface: number;
  hauteurSousPlafond: number;
  longueurMurs: number;
  surfaceMurs: number;
  typeLocation: TypeLocation;
  longueurSol?: number;
  largeurSol?: number;
};

export enum TypeRemise {
  Montant = 'montant',
  Pourcentage = 'pourcentage',
  Empty = 'empty',
}

export type Remises = {
  type: TypeRemise;
  values: Array<{ label: string; value: number }>;
};

export type Prime = {
  label: string;
  amount: number;
};

export type Attachment = {
  file: File;
  fileUrl: string;
  imageUrl: string;
  id: string;
  label: string;
};

export type LeadAssignmentConfig = {
  availability?: boolean;
  departments?: string[];
  acceptPaid?: boolean;
  acceptBarnes?: boolean;
  acceptHemea?: boolean;
  isCityManagerOf?: string[];
};

export type PersonConfig = {
  leadAssignment: LeadAssignmentConfig;
};

export enum PersonRole {
  Admin = 'admin',
  TeamLeadPartenariat = 'team_lead_partenariat',
  ResponsablePartenariat = 'responsable_partenariat',
  TeamLeadQualite = 'team_lead_qualite',
  CustomerSuccessManager = 'customer_success_manager',
  CustomerCare = 'customer_care',
  TravauxPlannerInterne = 'travaux_planner_interne',
  TravauxPlannerExterne = 'travaux_planner_externe',
  TeamLeadCDP = 'team_lead_cdp',
  CDP = 'cdp',
  CDPL = 'cdpl',
  Compta = 'compta',
  RH = 'rh',
  Recruteur = 'recruteur',
  TeamLeadMarketing = 'team_lead_marketing',
  ResponsableAcquisition = 'responsable_acquisition',
  NoRole = 'no_role',
  ArchitecteInterne = 'architecte_interne',
}

export type Team = {
  members: Person[];
  uuid: string;
  label: TeamsLabel;
};

export type Person = {
  uuid: string;
  firstName: string;
  lastName: string;
  fullName: string;
  jobTitle: string;
  archived: boolean;
  bonusPicture?: string;
  officialPicture?: string;
  arrivalDate: string;
  jobDescription: string;
  birthDate?: string;
  email: string;
  mobilePhoneNumber?: string;
  fixedPhoneNumber?: string;
  nickname?: string;
  config: PersonConfig;
  managerUuid?: string;
  recruiterUuid?: string;
  teams?: Team[];
  role?: PersonRole;
  cdplInformation?: CDPLInformation;
};

export type CDPLInformation = {
  numeroTVA: string;
  siret: string;
  asujetiTVA: boolean;
  bankAccountOwner: string;
  iban: string;
  bic: string;
  ribUrl: string;
  statutEntreprise: string;
  companyCreationDate: string;
  capital: number;
  RCS: string;
  documents: DownloadableFile[];
};

export enum TeamsLabel {
  Compta = 'Compta',
  Admin = 'Admin',
  Product = 'Product',
  Ops = 'Ops',
  ClubPro = 'ClubPro',
  Supply = 'Supply',
  CDPL = 'CDPL',
  RH = 'RH',
  Archi = 'Archi',
  Partenaires = 'Partenaires',
  TravauxPlanner = 'TP',
  CustomerCare = 'CustomerCare',
}

export type UserProfile<ProCompany> = {
  anonymous?: boolean;
  uuid: string;
  uniceURL?: string;
  company: ProCompany;
  isHemeaEmployee: boolean;
  fullName: string;
  firstName?: string;
  lastName?: string;
  phoneNumber: string;
  email: string;
  defaultTauxTVA: number;
  isWebPushEnabled: boolean;
  webPushIds?: string[];
  isProCompanyAdmin: boolean;
  signature?: {
    content: string;
    enabled: boolean;
    logoEnabled: boolean;
  };
  hasSeenDevisTsAnnouncement: boolean;
};

export type ConsentPayload = { [K in Consent]: ConsentValue };
export type GrantedConsents = {
  isAnalyticsConsentGranted: boolean;
  isAdConsentGranted: boolean;
  isPersonalizationConsentGranted: boolean;
};

export type BonCommandeLigne = {
  uuid?: string;
  designation: string;
  description?: string;
  normalizedIdentifier?: string;
  proBaseDePrixId?: string;
  quantite: number;
  unite: UnitValue;
  prixUnitaireHT: number;
  locations: LigneLocation[];
  marge?: number;
  selected: boolean;
};

export type BonCommandeLot = {
  uuid: string;
  label: string;
  prixTotalHT?: number;
  lignes: BonCommandeLigne[];
};

export type Contractor = {
  uuid: string;
  updatedAt: string;
  name: string;
  phoneNumber?: string;
  email?: string;
  billingFormattedAddress?: string;
  billingGoogleMapsAddress?: GooglemapsAddress;
  amountHT: number;
  siret?: string;
  fullname?: string;
  capital?: number;
  certificationsRGE?: DownloadableFile[];
};

export type DevisContractor = {
  uuid: string;
  name: string;
  billingFormattedAddress?: string;
  phoneNumber?: string;
  siret?: string;
  certificationsRGE?: DownloadableFile[];
  lots: string[];
};

export type MediateurConsommation = {
  uuid: string;
  proCompanyUuid: string;
  googleMapsAddress: GooglemapsAddress;
  fullName: string;
  phoneNumber: string;
  website?: string;
};

export type BonCommande = {
  uuid: string;
  token: string;
  proDevisToken: string;
  dealUuid: string;
  proCompanyUuid: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  sentAt?: string;
  signatureDate?: string;
  lots: BonCommandeLot[];
  locations: DevisLocation[];
  status: DevisStatuses;
  prixTotalHT: number;
  montantsTVA: MontantTVA[];
  marge: number;
  archived: boolean;
  contractorUuid: string;
};

export type Meeting = {
  uuid: string;
  invitee: string;
  inviter: string;
  startAt: string;
  createdAt: string;
  updatedAt: string;
  canceledAt?: string;
  hasOccurred: boolean;
  calendlyRescheduleUrl?: string;
  calendlyCancelUrl?: string;
};

export type SuiviLigneLegacy = {
  uuid: string;
  designation: string;
  description?: string;
  completionPercentage: number;
  normalizedIdentifier?: string;
  proBaseDePrixId?: string;
  comment: string;
  prixUnitaireHT: number;
  quantite: number;
  unite: UnitValue;
  locations: LigneLocation[];
};

export type SuiviLot = {
  uuid: string;
  label: string;
  lignes: SuiviLigneLegacy[];
};

export type SuiviChantier = {
  uuid: string;
  token: string;
  proDevisToken: string;
  clientUuid: string;
  dealUuid: string;
  proCompanyUuid: string;
  title: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  lots: SuiviLot[];
  locations: DevisLocation[];
};

type FileWithMetadata = {
  uuid: string;
  name: string;
  type: string;
  body: File;
};

export type UploadFilesPayload = {
  projectUuid?: string;
  files: FileWithMetadata[];
  onUpdate: (progressEvent: ProgressEvent, fileIndex: number) => void;
  onSuccess: (fileIndex: number) => void;
  onError: (fileIndex: number) => void;
};

export type CertificationRGEType =
  | 'qualiforageRGE'
  | 'qualibatRGE'
  | 'qualiboisRGE'
  | 'qualigazRGE'
  | 'qualipacRGE'
  | 'qualipvRGE'
  | 'qualisolRGE'
  | 'RGEChauffage'
  | 'RGEEcoArtisan';

export type CertificationRGE = {
  uuid: string;
  certification: DownloadableFile;
  certificationType: CertificationRGEType;
  dateDebutValidite: string;
  dateFinValidite: string;
  downloadUrl?: string;
  name?: string;
};

export type InsurancePayload = {
  dateDebutValidite: string;
  dateFinValidite: string;
  file: DownloadableFile;
  label: string;
  slug?: string;
};

export type ProCompanyInsurance = {
  id: string;
  fileUrl?: string;
  label: string;
  dateDebutValidite: string;
  dateFinValidite: string;
  isValid?: boolean;
  isDeleted?: boolean;
};

export type CompanyActivity =
  | 'grosOeuvre'
  | 'renovationInterieure'
  | 'specialiste'
  | 'archiInterieur'
  | 'archiHMONP'
  | 'bureauEtudes';

export const optionActivity: {
  value: CompanyActivity;
  label: string;
}[] = [
  {
    value: 'grosOeuvre',
    label: 'Gros œuvre',
  },
  {
    value: 'renovationInterieure',
    label: 'Rénovation intérieure',
  },
  {
    value: 'specialiste',
    label: 'Spécialiste',
  },
  {
    value: 'archiInterieur',
    label: "Architecte d'intérieur",
  },
  {
    value: 'archiHMONP',
    label: 'Architecte HMONP',
  },
  {
    value: 'bureauEtudes',
    label: "Bureau d'études techniques",
  },
];

export type DevisFacturationStatus = {
  totalMontantDejaPayeTTC: number;
  totalDejaCreeHT: number;
  totalDejaFactureHT: number;
  totalDejaFactureTTC: number;
  percentageDejaPayeTTC: number;
  totalMontantEnAttentePaiementTTC: number;
  percentageEnAttentePaiementTTC: number;
  totalMontantRestantAFacturerTTC: number;
  percentageRestantAFacturerTTC: number;
  referenceAmountTTC: number;
  totalMontantAvoirHT: number;
  totalMontantAvoirTTC: number;
  totalFactureSoldeTTC: number;
  isClotured: boolean;
  hasCloture: boolean;
  factureSoldeTotals?: FactureSoldeTotals;
};

export type GenericFacture = {
  isAvoir?: boolean;
  prixTotalTTC: number;
  prixTotalHT: number;
  status: FactureStatuses;
  lots: Lot[];
  montantsTVA: MontantTVA[];
  isCloture?: boolean;
  isSituation?: boolean;
};

export enum SuiviLigneStatus {
  Agreed = 'agreed',
  Rejected = 'rejected',
  Pending = 'pending',
  Disputed = 'disputed',
}

export enum ClientActionType {
  TakeRdv = 'takeRdv',
  AddDpe = 'addDpe',
  AddPlans = 'addPlans',
  AddPhotos = 'addPhotos',
  SeeGuides = 'seeGuides',
  AddInspirations = 'addInspirations',
  SeeArchiDevis = 'seeArchiDevis',
  SeeProDevis = 'seeProDevis',
  SeeArchiDevisTs = 'seeArchiDevisTs',
  SeeProDevisTs = 'seeProDevisTs',
  AddProvisions = 'addProvisions',
  SeeSuiviChantier = 'seeSuiviChantier',
  ValidateReceptionChantier = 'validateReceptionChantier',
  ValidateLeveeReserves = 'validateLeveeReserves',
  ValidateInitialAdp = 'validateInitialAdp',
}

export enum ActionStatus {
  Available = 'available',
  Skipped = 'skipped',
  Validated = 'validated',
}

export type ClientAction = {
  title: string;
  secondaryTitle?: string;
  iconName: string;
  to: string;
  actionStatus: ActionStatus;
  actionType: ClientActionType;
  entityIdentifier?: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  validatedAt?: string;
};

export type DocumentConfig = {
  label: string;
  icon: React.ReactNode;
  description: string | React.ReactNode;
};

export type CompensationType = 'penalties' | 'compensation';

export type SlotRDV = {
  start: Date;
  end: Date;
  type: 'booked' | 'available';
  label?: string;
  isCalendly?: boolean;
};

export enum EtapeProjet {
  Etude = 'etude_project',
  Archi = 'etude_archi',
  Pro = 'pro',
  Devis = 'devis',
  AvantChantier = 'pre_chantier',
  Chantier = 'chantier',
  ApresChantier = 'post_chantier',
}

export type Project = {
  uuid: string;
  files: DownloadableFileWithTag[];
  pipedriveDealUrl?: string;
  pipedriveDealId?: number;
  etape?: EtapeProjet;
  title: string;
  bookedSlots: SlotRendezVousView[];
  slotsRendezVous: SlotRendezVousView[];
  chunkedSlotsRendezVous: SlotRendezVousView[];
  description?: string;
  minutesBetweenRdv: number;
  enableConformiteDevis: boolean;
} & TypologieProject;

export interface SlotRendezVousView {
  start: string;
  end: string;
  label?: string;
  isCalendly?: boolean;
}

export type Location = {
  uuid: string;
  typeLocation: TypeLocation;
  label?: string;
  surface: number;
};

export enum TypeTravaux {
  RenovationPartielle = 'renovation_partielle',
  RenovationComplete = 'renovation_complete',
  RenovationEnergetique = 'renovation_energetique',
  Extension = 'extension',
  Construction = 'construction',
  Surelevation = 'surelevation',
  AmenagementExterieur = 'amenagements_exterieur',
}

export enum UtilisationLogement {
  ResidencePrincipale = 'Résidence principale',
  ResidenceSecondaire = 'Résidence secondaire',
  Locatif = 'Locatif',
  Autre = 'Autre',
}

export enum StadeAchat {
  Curieux = 'curieux',
  BienVisite = 'bien_visite',
  Offre = 'offre',
  OffreAcceptee = 'offre_acceptee',
  OffreNonAcceptee = 'offre_non_acceptee',
  CompromisSigne = 'compromis_signe',
  Proprietaire = 'proprietaire',
}

export enum TypeBien {
  Appartement = 'appartement',
  Maison = 'maison',
  MaisonBriques = 'maison_briques',
  MaisonBois = 'maison_bois',
  MaisonContainer = 'maison_container',
  Immeuble = 'immeuble',
  BienProfessionel = 'bien_professionnel',
  Bureaux = 'bureaux',
  RestauBar = 'restau_bar',
  Hotel = 'hotel',
  SalonCoiffure = 'salon_coiffure',
  CabinetMedical = 'cabinet_medical',
  Autre = 'other',
}

export enum Rooms {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  T5 = 'T5',
  T6Plus = 'T6 +',
  Studio = 'Studio',
}

export enum Dpe {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export type TypologieProject = {
  typeLogement?: TypeBien;
  rooms?: Rooms;
  floor?: number;
  floorCount?: number;
  hasElevator?: boolean;
  mainGamme: Gamme;
  locations: Location[];
  hauteurSousPlafond?: number;
  address?: GooglemapsAddress;
  stadeAchat?: StadeAchat;
  utilisationLogement?: UtilisationLogement;
  dpe?: Dpe;
  debutTravaux?: string;
  typeTravaux?: TypeTravaux;
  isRenovationEnergetique?: boolean;
  budgetTravaux?: number;
  surfaceTotale?: number;
  comments: string;
  prixHT?: number;
};
