import React from 'react';

import classNames from 'classnames';

import { ImgLazy, Props as ImageProps } from '@travauxlib/shared/src/components/Images/ImgLazy';

import { GenericLinkProps, UnstyledGenericLink } from '../Links';

export type ImageVariant = 'side' | 'top-fit' | 'top-full' | 'full';

export type CardState = 'elevated' | 'outlined' | 'filled';

const classNamesByState: { [key in CardState]: string } = {
  elevated: 'shadow-sm hover:shadow-md active:ring-2 active:ring-neutral-800',
  outlined: 'border border-neutral-300',
  filled: 'shadow-xxs',
};

export type Props = {
  imageVariant?: ImageVariant;
  className?: string;
  image?: ImageProps;
  tag?: React.ReactElement;
  state?: CardState;
  disabled?: boolean;
  bodyClassNames?: string;
  withGradient?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const RawCard: React.FC<Props> = ({
  className,
  children,
  image,
  tag,
  imageVariant = 'top-fit',
  state = 'filled',
  disabled = false,
  withGradient = true,
  bodyClassNames,
  ...rest
}) => {
  const { className: imageClassName, url: imageUrl, ...restImageProps } = image || {};
  const displayTag = imageVariant !== 'side' && tag;

  if (imageVariant === 'full') {
    return (
      <div
        className={classNames(
          classNamesByState[state],
          { 'pointer-events-none': disabled },
          'rounded-xs overflow-hidden relative flex flex-col justify-end',
          className,
        )}
        {...rest}
      >
        {imageUrl && (
          <div className="absolute inset-0">
            <ImgLazy
              url={imageUrl}
              className={classNames('object-cover h-full w-full', imageClassName)}
              {...restImageProps}
            />
          </div>
        )}
        {withGradient && (
          <>
            <div className="absolute inset-0 bg-gradient-to-b from-neutral-900/[.0512] to-neutral-900/[0.64]" />
            {displayTag && <div className="absolute top-md left-md">{tag}</div>}
            <div className="z-10 m-lg">{children}</div>
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        classNamesByState[state],
        disabled ? 'bg-neutral-100' : 'bg-neutral-0',
        { 'pointer-events-none': disabled },
        'rounded-xs overflow-hidden',
        { 'flex items-start': imageVariant === 'side' },
        { 'flex flex-col': imageVariant === 'top-full' },
        className,
      )}
      {...rest}
    >
      {imageUrl && (
        <div
          className={classNames(
            { relative: tag },
            { 'px-md pt-md': imageVariant === 'top-fit' },
            { 'py-md pl-md shrink-0': imageVariant === 'side' },
          )}
        >
          {displayTag && (
            <div
              className={classNames('absolute', {
                'top-md left-md': imageVariant === 'top-full',
                'top-xl left-xl': imageVariant === 'top-fit',
              })}
            >
              {tag}
            </div>
          )}
          <ImgLazy
            url={imageUrl}
            className={classNames(
              'object-cover',
              { 'rounded-xxs': imageVariant === 'side' },
              imageClassName,
            )}
            {...restImageProps}
          />
        </div>
      )}
      <div className={classNames('p-md', { grow: imageVariant === 'side' }, bodyClassNames)}>
        {children}
      </div>
    </div>
  );
};

export const Card: React.FC<Props & Pick<GenericLinkProps, 'href' | 'to'>> = ({
  to,
  href,
  ...rest
}) => {
  if (to || href) {
    return (
      <UnstyledGenericLink to={to} href={href} className="rounded-xs">
        <RawCard {...rest} />
      </UnstyledGenericLink>
    );
  }

  return <RawCard {...rest} />;
};
