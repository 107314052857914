import React, { useMemo } from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { mod } from '@travauxlib/shared/src/utils/math';

import { SelectedOptions } from './SelectedOptions';
import { DropdownOption } from './types';

import {
  iconSuffixClassName,
  InputErrorText,
  InputHelperText,
  makeInputContainerClassnames,
  makeInputLabelClassnames,
  makeInputMainClassnames,
} from '../Input/commons';

export type Props<T> = Omit<React.HTMLProps<HTMLDivElement>, 'onChange' | 'value'> & {
  value?: Array<T>;
  combobox?: boolean;
  options: DropdownOption<T>[];
  id: string;
  onChange: (newValue: Array<T>) => void;
  label?: string | React.ReactElement;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  optionsContainerClassName?: string;
  optionClassName?: string;
  placeholder?: string;
  helperText?: string | React.ReactElement;
  error?: string;
  disabled?: boolean;
  onFilterFn?: (filter?: string | undefined) => (option: DropdownOption<T>) => boolean;
  defaultOption?: DropdownOption<T>;
  autoFocus?: boolean;
};

const genericLowerCaseFilter = <T,>(
  callBack: (option: DropdownOption<T>, filter: string) => boolean,
  filter?: string,
): ((option: DropdownOption<T>) => boolean) => {
  const lowerCasedFilter = filter?.toLocaleLowerCase();
  return (option: DropdownOption<T>) => !lowerCasedFilter || callBack(option, lowerCasedFilter);
};

export const filterStartsWithNonCaseSensitive = <T,>(
  filter?: string,
): ((option: DropdownOption<T>) => boolean) =>
  genericLowerCaseFilter(
    (option, lowerCasedFilter) => option.label.toLocaleLowerCase().startsWith(lowerCasedFilter),
    filter,
  );

export function DropdownMulti<T>({
  id,
  options,
  value = [],
  onChange,
  label,
  placeholder,
  helperText,
  error,
  onFilterFn,
  disabled,
  containerClassName,
  className,
  inputClassName,
  optionsContainerClassName,
  optionClassName,
  defaultOption,
  combobox,
  ...rest
}: Props<T>): React.ReactElement {
  const [inputPosition, setInputPosition] = React.useState<number>(0);
  const [menuIndex, setMenuIndex] = React.useState<number>();
  const [searchText, setSearchText] = React.useState<string>('');
  const [isOpen, setIsOpen] = React.useState(false);
  const dropDownRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const menuRef = React.useRef<HTMLInputElement | null>(null);

  const handleClose = (): void => {
    if (isOpen) {
      setIsOpen(false);
    }
    setSearchText('');
    setMenuIndex(undefined);
  };

  useOnClickOutside(dropDownRef, handleClose);

  const onFilterChanged = (newValue: string): void => {
    if (!isOpen) {
      setIsOpen(true);
    }
    setSearchText(newValue);
    setMenuIndex(undefined);
  };

  const filterWithDefault = React.useCallback(
    onFilterFn ? onFilterFn : filterStartsWithNonCaseSensitive,
    [onFilterFn],
  );

  const filteredOptions = React.useMemo(
    () => options.filter(filterWithDefault(searchText)),
    [options, searchText, filterWithDefault],
  );

  const onClickOption = (newOption: DropdownOption<T>): void => {
    if (value.includes(newOption.value)) {
      onChange(value.filter(v => v !== newOption.value));
    } else {
      onChange([...value, newOption.value]);
      if (value.length - 1 === inputPosition) {
        setInputPosition(inputPosition + 1);
      }
    }
  };

  const menuOptions = useMemo(
    () =>
      filteredOptions.map((option: DropdownOption<T>, index) => (
        <Checkbox
          className={classNames(
            '!mb-0 !flex pl-sm py-sm hover:bg-gray-100',
            index === menuIndex && 'bg-gray-100',
          )}
          style={{
            order: index,
          }}
          key={`${option.label}${option.value}`}
          label={option.label}
          onChange={() => {
            onClickOption(option);
          }}
          checked={value.includes(option.value)}
        />
      )),
    [filteredOptions, menuIndex, options, value, onClickOption],
  );

  const selectedOptions = useMemo(
    () =>
      value
        .map(v => options.find(option => option.value === v))
        .filter(Boolean) as DropdownOption<T>[],
    [options, value],
  );

  const advanceMenuForward = (): void => {
    const newMenuIndex = menuIndex === undefined ? 0 : mod(menuIndex + 1, filteredOptions.length);
    setMenuIndex(newMenuIndex);

    if (menuRef.current) {
      const container = menuRef.current;
      const item = container.children[newMenuIndex] as HTMLDivElement;

      const containerRect = container.getBoundingClientRect();

      if (containerRect.height + container.scrollTop < item.offsetTop) {
        container.scrollTop += item.getBoundingClientRect().height;
      } else if (newMenuIndex === 0) {
        container.scrollTop = 0;
      }
    }
  };

  const advanceMenuBackward = (): void => {
    const newMenuIndex = !menuIndex ? filteredOptions.length - 1 : menuIndex - 1;
    setMenuIndex(newMenuIndex);

    if (menuRef.current) {
      const container = menuRef.current;
      const item = container.children[newMenuIndex] as HTMLDivElement;

      if (newMenuIndex === filteredOptions.length - 1) {
        container.scrollTop = container.scrollHeight;
      } else if (container.scrollTop > item.offsetTop) {
        container.scrollTop -= item.getBoundingClientRect().height;
      }
    }
  };

  const deletePreviousValue = (): void => {
    if (combobox) {
      onChange([]);
    } else {
      onChange(value.filter((_, index) => index !== inputPosition));
      if (value.length <= 1) {
        setInputPosition(0);
      } else {
        setInputPosition(mod(inputPosition - 1, value.length - 1));
      }
    }
  };

  return (
    <div
      onClick={() => {
        if (!disabled) {
          setIsOpen(true);
          inputRef.current?.focus();
        }
      }}
      ref={dropDownRef}
      {...rest}
      className={classNames(containerClassName || className, !disabled && 'cursor-pointer')}
    >
      <div
        className={classNames(
          makeInputContainerClassnames({ disabled, error }),
          '!w-full !pr-sm !pl-md !py-xxs',
        )}
      >
        <div
          className={classNames(
            'flex items-center w-full overflow-hidden pt-md gap-y-xxs -my-[1px] flex-wrap',
          )}
        >
          <SelectedOptions
            selectedOptions={selectedOptions}
            value={value}
            onChange={onChange}
            disabled={disabled}
            combobox={combobox}
          />

          <input
            size={Math.max(searchText.length, 1)}
            ref={inputRef}
            className={classNames(
              makeInputMainClassnames({ disabled, hasLabel: true }),
              '!p-0 !m-0 !h-[1.5rem]',
              selectedOptions.length !== 0 && '!w-auto',
            )}
            style={{
              order: inputPosition,
            }}
            autoFocus={isOpen}
            id={id}
            placeholder={selectedOptions.length === 0 ? placeholder : ''}
            value={searchText}
            onKeyDown={e => {
              if (e.code === 'ArrowDown') {
                advanceMenuForward();
              }

              if (e.code === 'ArrowUp') {
                advanceMenuBackward();
              }

              if (e.code === 'Enter' && menuIndex !== undefined) {
                e.preventDefault();
                if (menuIndex !== undefined) {
                  onClickOption(filteredOptions[menuIndex]);
                }
              }

              if (e.code === 'Backspace' && !searchText) {
                deletePreviousValue();
              }
              if (e.code === 'ArrowLeft' && !combobox && !searchText) {
                setInputPosition(mod(inputPosition - 1, value.length));
              }
              if (e.code === 'ArrowRight' && !combobox && !searchText) {
                setInputPosition(mod(inputPosition + 1, value.length));
              }
              if (e.code === 'Escape') {
                handleClose();
              }
            }}
            onChange={event => onFilterChanged(event.target.value)}
            disabled={disabled}
          />
          {label && (
            <label
              className={classNames(
                makeInputLabelClassnames({
                  value: searchText,
                  disabled,
                  hasPrefix: selectedOptions.length !== 0,
                }),
                '!pl-0',
              )}
              htmlFor={id}
            >
              {label}
            </label>
          )}
        </div>
        <div className={classNames(iconSuffixClassName, 'ml-xs self-start mt-xs')}>
          {isOpen ? (
            <ChevronUp
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            />
          ) : (
            <ChevronDown />
          )}
        </div>
      </div>
      {isOpen && (
        <>
          {menuOptions && menuOptions.length > 0 && (
            <div
              data-testid="open-menu"
              onClick={e => e.stopPropagation()}
              className="relative z-30 shadow-xs"
            >
              <Card
                className={classNames(
                  optionsContainerClassName,
                  'absolute w-full mt-xxs border !rounded',
                )}
                bodyClassNames="px-0 py-xs"
              >
                <div ref={menuRef} className="max-h-[12rem] overflow-auto">
                  {menuOptions}
                </div>
              </Card>
            </div>
          )}
        </>
      )}
      <InputErrorText error={error} />
      <InputHelperText helperText={helperText} />
    </div>
  );
}
