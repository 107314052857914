import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { Props as InputProps } from '../Input';

import { Dropdown as RawDropdown, Props as DropdownProps } from './index';

type WrapperProps<T> = FieldRenderProps<T> & InputProps;

export function Dropdown<T>({
  meta,
  options,
  label,
  id,
  helperText,
  placeholder,
  input,
  disabled,
  prefix,
  ...rest
}: WrapperProps<T>): React.ReactElement {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <RawDropdown
      options={options}
      label={label}
      id={id}
      helperText={helperText}
      error={error && touched ? error : undefined}
      disabled={disabled}
      placeholder={placeholder}
      onChange={input.onChange}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      {...rest}
      value={input.value as any}
    />
  );
}
type Props<T> = {
  name: string;
  onChange?: DropdownProps<T>['onChange'];
} & Omit<DropdownProps<T>, 'value' | 'onChange' | 'defaultOption'> &
  UseFieldConfig<T>;

export function DropdownField<T>(props: Props<T>): React.ReactElement {
  return <Field component={Dropdown} {...props} />;
}
